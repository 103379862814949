import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
	  isLoggedIn: false,
	  isAmiAdmin: false,
	  fiId: null,
	  fiName: null,
	  participantIds: [],
	  permissions: {},
  }),
  actions: {
	  setUser(data){
		  this.isLoggedIn = true;
		  this.fiId = data.fi_id;
		  this.fiName = data.fi_name;
		  this.participantIds = data.participant_ids;
		  this.permissions = data.permissions;
		  if(!data.fi_id){
			  this.isAmiAdmin = true;
		  }
	  }
  }
})
