<!-- Header: Header of Forms and Tables -->
<template>
    <div class="flex flex-col shadow-lg rounded-lg self-start">        
        <Header :title="title" :icon="icon" :buttons="headerButtons" :theme="theme"
            @close="this.$emit('close')"
            @buttonClick="e => { this.$emit(e); }"
            @click="focusFirstInput()" />
        <Notice31 v-if="error != ''" :notice="error" theme="alert" :showClose="true" @close="this.$emit('closeError')"/>
        <Notice31 v-if="localError != ''" :notice="localError" theme="alert" :showClose="true" @close="localError = ''"/>
        <div ref="slot" :class="`fnui-content ${columns > 1 ? 'flex-row flex-wrap' : 'flex-col' } flex border-l-2 border-r-2 py-2`">
          <Field v-for="f in fields" 
            ref="fields"
            :key="f.id"
            :class="`${f.width ? f.width : columns > 1 ? 'w-1/'+columns : 'w-full'}`"
            :label="f.label"
            :id="f.id"
            :icon="f.icon"
            :note="f.note"
            :noteEmit="f.noteEmit"
            :options="f.options"
            :minLength="f.minLength"
            :isRequired="f.isRequired"
            :maxLength="f.maxLength"
            :type="f.type"
            :initialValue="f.initialValue"
            @enter="onSubmit"
            @valueUpdated="(e)=>valueUpdated(e, f)"
            @noteClicked="this.$emit(f.noteEmit)"
            @buttonClicked="this.$emit(f.emit, values)"
            :placeholder="f.placeholder"></Field>
            <slot />
        </div>
        <slot name="afterInputs" class="p-2" />

        <div v-if="showSubmit || showCancel" 
          class="p-1 w-full flex flex-row fnui-content border-b-2 md:border-l-2 md:border-r-2 md:rounded-b-lg overflow-hidden"
          style="height: 50px">
            <div class="w-full flex flex-row gap-x-1">        
                <Button v-if="bioMode == 'camera'" icon="login-face" @click="onBio" title="Face Camera Login" />  
                <Button v-else-if="bioMode == 'fingerprint'" icon="fingerprint" @click="onBio" title="Fingerprint Login" />  
                <Button v-if="showSubmit"
                    @click="onSubmit"
                    class="flex-grow"
                    size="large"
                    :theme="onCooldown ? 'disabled' : 'submit'"
                    :label="submitText ? submitText : 'Submit'" />
                <Button v-if="showCancel"
                    @click="onCancel"
                    :class="`${!showSubmit ? 'flex-grow' : ''}`"
                    size="large"
                    :theme="onCooldown ? 'disabled' : 'cancel'"
                    :label="cancelText ? cancelText : 'Cancel'" />
            </div>
        </div>
        <div v-else class="border-b-2 h-4 border-l-2 border-r-2 rounded-b-lg" />
    </div>
</template>

<script>
import Button from "@/components/fnui/Button.vue";
import Field from "@/components/fnui/Field.vue";
import Header from "@/components/fnui/Header.vue";
import Notice31 from "@/components/fnui/Notice.vue";

import Icon from "@/components/fnui/Icon.vue";

import { ref } from "vue";

export default {
  components: {
    Button,
    Field,
    Header,
    Notice31,
    Icon
  },
  props: {
    autoFocus: Boolean,
    bioMode: String,
    cancelText: String,
    columns: {
      type: Number,
      default: 1
    },
    error: {                             //icon: ID of the icon in the form header
      type: String,
      default: ""
    },
    validate: Boolean,
    fields: Object,
    headerButtons: Object,
    icon: String,
    refreshOnFieldChange: Boolean,      // Reset field values andfocus 1st input when fields changed
    showSubmit: { type: Boolean, default: true },
    showCancel: Boolean,
    submitText: String,
    theme: { type: String, default: 'header' }, // header or content for more subtle header
    title: String
  },
  mounted(){
    this.initializeForm(this.$props.fields);
  },  
  watch: {
        fields: {
            deep: true,
            handler(newFields, oldFields){
                this.handleFieldsChange(newFields, oldFields);
            }
        }
    },
    computed: {
        values(){    
            if(!this.$refs.fields) return {};

            let vals = {};        
            for(var ii = 0; ii < this.$refs.fields.length; ii++){
                let field = this.$refs.fields[ii];
                vals[field.id] = field.value
            }
            return vals
        }
    },
  methods: {
    // Set all field values to ''
    clear(){  
        for(var key in this.values){
            this.values[key] = '';
        }
        for(var ii = 0; ii < this.$refs.fields.length; ii++){
            this.$refs.fields[ii].value = '';
        }
    },
    setValue(key, value){
        this.values[key] = value
        for(var ii = 0; ii < this.$refs.fields.length; ii++){
            let field = this.$refs.fields[ii];
            if(this.$refs.fields[ii].id == key){
                this.$refs.fields[ii].value = value;
                break;
            }
        }
    },
    // Get the value of a field from this form
    getValue(id){
        if(!this.$refs.fields) return '';    
        for(var ii = 0; ii < this.$refs.fields.length; ii++){
            let field = this.$refs.fields[ii];
            if(field.id == id) return field.value;
        }
        return '';
    },
    initializeForm(fields){
        
        // Update fv with new fields
        this.fv = {};
        if(fields)
        for(var ii = 0; ii < fields.length; ii++){
            let field = fields[ii];
            this.fv[field.id] = new ref(field.initialValue ? field.initialValue : '')
        }

        // Focus first input if form is set to
        this.$nextTick(() => {
            if(this.$props.autoFocus)
                this.focusFirstInput();
        });
    },/*
    updateValues(values){
        return;
        console.log('___');
        console.log('form.updateValues.values: ', values)
        / *
        console.log('this.$props.fields: ', this.$props.fields);
        
        for(var ii = 0; ii < this.$props.fields.length; ii++){
            let field = this.$props.fields[ii];
            console.log('field: ', field);
            if(values[field.id])
                this.fv[field.id] = values[field.id]
        }* /
        
        for(var key in values){
            let v = values[key];
            this.fv[key] = v;
            if(this.$props.fields && this.$props.fields[key])
                this.$props.fields[key].initialValue = v;
        }
    },*/
    //Focus the first input of the form
    focusFirstInput(){
        let slotContent = this.$refs.slot
        if(!slotContent) return;

        let firstInput = slotContent.querySelector('input');
        if(firstInput)
            firstInput.focus();
    },
    handleFieldsChange(newFields, oldFields){

        if(!this.$props.refreshOnFieldChange)
            return;

        this.initializeForm(newFields);
        
    },
    // onCancel: Emit cancel event to parent
    async onCancel() {        
        if(!this.onCooldown){
            this.emit("cancel");        
            this.onCooldown = true;
            clearTimeout(this.cooldown);
            this.cooldown = setTimeout(this.resetCooldown, this.cooldownTime * 1000);
        }
    },
    // onSubmit: Emit submit event to parent
    async onSubmit(evt) {
      
        if(evt != undefined)
            evt.preventDefault();    

        this.localError = '';

        // Valid input if enabled
        let issueFound = false;
        if(this.$props.validate && this.$refs.fields){
            // Make sure that each field in the form is valid
            for(var ii = 0; ii < this.$refs.fields.length; ii++){            
                let fieldCheck = this.$refs.fields[ii].validationCheck();
                if(!fieldCheck.success){
                    issueFound = true;
                    this.localError = fieldCheck.error;
                    break;
                }
            }
        }

        if(!this.onCooldown && !issueFound){
            this.emit("submit", this.fv);      
            this.onCooldown = true;
            clearTimeout(this.cooldown);
            this.cooldown = setTimeout(this.resetCooldown, this.cooldownTime * 1000);
        }
    },      
    onBio() {
      this.emit("bio", this.fv);
    },
    resetCooldown(){
        this.onCooldown = false;
    },
    valueUpdated(evt, field){
      this.fv[evt.id] = evt.value;
        this.$emit('updateFormValues', this.fv);
      //Check for a field specific emit
      if(field.changeEmit)
        this.$emit(field.changeEmit, evt.value);
    }
  },
  setup(props, { emit }) {
    
    const cooldownTime = ref(2); //in seconds
    const onCooldown = ref(false)
    const cooldown = ref()
    const fv = ref({})
    const localError = ref('');

    return { 
        cooldown,
        cooldownTime,   
        emit,
        fv,
        localError,
        onCooldown
    };
  }
};
</script>
