<template>
    <Table ref="table" title="Customer Credit Transfers" icon="receipt-empty" class="max-w-screen-lg" :recordsLoading="recordsLoading">
        <template #header><div class="flex flex-row items-center rounded px-2"><Field :initialValue="date" @change="dateChange" title="Settlement Date" type="date" class="px-1 rounded-sm fnui-content py-0 ml-1 mb-1" :noPadding="true" /></div></template>
        <Pages v-if="pages > 1" :page="page" :pages="pages" :total="total" @next="nextPage"  @previous="previousPage" />
        <div v-else-if="transfers.length == 0">
            <Notice :notice="`No transfers found`" />
        </div>
        <!-- Transfers -->
            <div v-for="t in transfers" :key="t.id" 
                :class="`py-4 border-b flex flex-row flex-wrap  ${focusedTransfer[0] && focusedTransfer[0].transfer_id == t.id ? 'fnui-content' : 'fnui-content-hover cursor-pointer'}`"
            @click="fetchTransferDetails(t.id)">
            <!-- Focused Transfer header -->
            <Header v-if="focusedTransfer[0] && focusedTransfer[0].transfer_id == t.id" title="Focused Transfer" :showClose="true" @close="focusedTransfer = {}" @click.stop.prevent class="w-full mb-2" :hasRoundedTop="false" />
            <!-- Buttons Toolbar -->            
            <div v-if="focusedTransfer[0] && focusedTransfer[0].transfer_id == t.id" class="w-full flex justify-start px-2 pb-2 gap-x-2">
                <Button v-if="t.flags['DEBTOR']" size="sm" theme="secondary-button" class="border flex-grow" label="Return Request" icon="receipt-refund" @click.stop.prevent="mode = 'return'" />
                <Button v-else size="sm" theme="secondary-button" class="border flex-grow" label="Return" icon="receipt-refund" @click.stop.prevent="mode = 'return'" />
                <Button size="sm" theme="secondary-button" class="border flex-grow" label="Information Request" icon="document-text" @click.stop.prevent="mode = 'info'" />
                <Button size="sm" theme="secondary-button" class="border flex-grow" label="Possible Duplicate" icon="document-duplicate" @click.stop.prevent="mode = 'duplicate'" />
            </div>
            <div v-for="field in 
                [ { title: 'Created At', icon: 'clock', value: displayDate(t['created_at']) },
                  { title: 'Status', icon: 'receipt', label: 'Status', value: t['status'] },
                  { title: 'Counter Party RTN', icon: 'building-office', label: 'RTN', value: t['counterparty_rtn'] } ,
                  { title: 'Account', icon: 'user', label: 'Account', value: `${t['name']}, ${t['account']} ${t.flags['DEBTOR'] ? '(Is Debtor)' : ''}` },
                  { title: 'Amount', icon: 'dollar', label: 'Amount', value: `$${parseFloat(t['amount']).toFixed(2)}` },
                  { title: 'Counter Party Account', icon: 'user', label: 'Counter Party', value: `${t['counterparty_name']}, ${t['counterparty_account']}` },
                  { title: 'Tracer', icon: 'hashtag', label: 'Tracer', value: t['tracer'] },
                  { title: 'Tranfer ID', icon: 'identification', label: 'Transfer ID', value: `${t['id']}`, width: 'w-2/3'} ]" 
            :key="field.title" :title="field.title" 
            :class="`${focusedTransfer[0] && focusedTransfer[0].transfer_id != t.id ? 'opacity-30' : 'opacity-100'} flex items-center ${field.width ? field.width : 'w-1/3'} px-2`"><Icon v-if="field.icon" :id="field.icon" class="h-4 w-4 mr-1" /><span class="font-bold mr-1">{{field.label ? field.label + ': ' : ''}}</span> {{field.value}}</div>
            <div v-if="focusedTransfer[0] && focusedTransfer[0].transfer_id == t.id" class="w-full flex flex-col">
                <!-- Return Request Form -->
                <Form v-if="mode == 'return'" theme="secondary" @submit="returnRequestSubmit" submitText="Submit Return Request" :showCancel="true" @cancel="mode = ''" class="mx-auto py-2 w-2/3" title="Return Request" icon="receipt-refund" 
                    :ref="`returnRequestForm${t.id}`" :validate="true" 
                    :fields="[{type: 'reasonCode', label: 'Reason Code', id: 'code' }, 
                        {type: 'area', label: 'Additional Information (optional)', isRequired: false, id: 'reason' }]"/>
                <!-- Possible Duplicate Form -->
                <Form v-else-if="mode == 'duplicate'" theme="secondary" @submit="duplicateRequestSubmit" submitText="Submit Possible Duplicate" :showCancel="true" @cancel="mode = ''" class="mx-auto py-2 w-2/3" title="Possible Duplicate" icon="document-duplicate" 
                :ref="`duplicateForm${t.id}`" :validate="true"><Notice notice="Mark this transfer as a possible duplicate" theme="alert" /></Form>
                <!-- Info Request Form -->
                <Form v-else-if="mode == 'info'" :columns="2" theme="secondary"  submitText="Submit Information Request" :showCancel="true" @cancel="mode = ''" class="mx-auto py-2 w-5/6" title="Information Request" icon="info"
                :ref="`infoRequestForm${t.id}`" @addInfo="addInfo" @fieldTypeChanged="fieldTypeChanged" @submit="infoRequestSubmit"  
                    :fields="[{ id: 'type', type: 'select', label: 'Missing/Incorrect', changeEmit: 'fieldTypeChanged', options: [{label: 'Missing', value: 'missing'},{label: 'Incorrect', value: 'incorrect'}] },
                              { id: 'field', type: infoFieldType, label: 'Field' },
                              { id: 'message', type: 'text', label: 'Additional Information (optional)', width: 'w-full', isRequired: false },
                              { id: 'add', type: 'button', label: 'Add Field To Request', width: 'w-full', icon: 'plus-circle', emit: 'addInfo' }]">

                    <div class="w-full flex flex-row px-2 gap-x-2">
                        <!--Missing/Incorrect Fields-->
                        <div v-for="l in  [{ title: 'Missing Info', list: infoMissing}, { title: 'Incorrect Info', list: infoIncorrect }]" :key="l.title" 
                            class="flex flex-col w-1/2">
                            <Header theme="secondary" :title="l.title" class="w-full" />
                            <div v-for="info in l.list" :key="info.field" class="flex flex-row justify-between w-full text-left p-1">
                                <Icon id="x" title="Remove Field" @click="removeField(info.code, l.list)" class="h-6 w-6 mr-2 flex-shrink-0 opacity-70 hover:opacity-100 cursor-pointer duration-150" />
                                <div class="flex flex-col text-sm flex-grow">
                                    <span class="font-bold">{{info.code}}:
                                        <span v-if="l.title == 'Missing Info' && appStore.codes.missing_info[info.code]" class="font-bold">{{appStore.codes.missing_info[info.code].desc}}</span>
                                        <span v-if="l.title == 'Incorrect Info' && appStore.codes.incorrect_info[info.code]" class="font-bold">{{appStore.codes.incorrect_info[info.code].desc}}</span> 
                                    </span>
                                    <span v-if="info.message" class="font-mono flex-grow text-truncate" :title="info.message">Addt Info: {{info.message}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                <Notice v-else-if="mode == 'requestSent'" class="p-2 w-2/3 mx-auto my-2" notice="Request Successfully Submit" :showClose="true" @close="mode = ''" />
                <!-- Focused Message -->
                <Table v-else-if="focusedMessage.id" theme="secondary" class="w-5/6 mt-2 mx-auto" :title="focusedMessage.description" :showClose="true" @close="focusedMessage = {}">
                <div class="w-full flex flex-row flex-wrap fnui-content">
                    <div v-for="field in [
                        { title: 'ID', value: focusedMessage.id },
                        { title: 'Created At', value: focusedMessage.created_at },
                        { title: 'Inserted At', value: focusedMessage.inserted_at },
                        { title: 'Participant ID', value: focusedMessage.participant_id },
                        { title: 'Transfer ID', value: focusedMessage.transfer_id },
                        { title: 'Case ID', value: focusedMessage.case_id },
                        { title: 'Description', value: focusedMessage.description },
                        { title: 'MDI', value: focusedMessage.mdi }
                    ]" :key="field.title" class="border w-1/2 p-1 text-left">
                        <span class="font-bold">{{field.title}}:</span> {{field.value}}
                    </div>
                    <div v-for="(value, key) in focusedMessage.flags" :key="key"
                        class="border w-1/4 text-left p-1">
                        <span class="font-bold">{{key}}:</span> {{value}}                        
                    </div>
                </div>
            </Table>
                <!-- Messages -->
                <div v-else class="mx-auto w-2/3 flex flex-col flex-wrap mt-2 fnui-content rounded-sm">                    
                    <div v-for="m in focusedTransfer" class="w-full p-2 flex flex-row justify-start items-center" :key="m.description" :title="JSON.stringify(m)">
                        <Button @click="focusedMessage = m" size="xs" icon="document-text" title="View Message Details" />
                        <div class="flex flex-col text-left items-start ml-1">
                            <div class="font-bold leading-4">{{m.description}}</div>
                            <div class="font-mono text-sm">{{displayDate(m.created_at)}}</div>
                        </div>      
                    </div>
                </div>
            </div>
            <Header v-if="focusedTransfer[0] && focusedTransfer[0].transfer_id == t.id" class="w-full" :hasRoundedTop="false" />
        </div>
        <Pages v-if="pages > 1" :page="page" :pages="pages" :total="total" @next="nextPage"  @previous="previousPage" />
    </Table>
</template>

<script>
import { ref } from 'vue'

import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'

import Button from '@/components/fnui/Button.vue'
import Field from '@/components/fnui/Field.vue'
import Form from '@/components/fnui/Form.vue'
import Header from '@/components/fnui/Header.vue'
import Icon from '@/components/fnui/Icon.vue'
import Notice from '@/components/fnui/Notice.vue'
import Pages from '@/components/fnui/Pages.vue'
import Table from '@/components/fnui/Table.vue'

export default {
    name: 'CCT Transfers',
    components: {
        Button,
        Field,
        Form,
        Header,
        Icon,
        Notice,
        Pages,
        Table
    },
	computed: {
		...mapStores(useUserStore, useAppStore),
  },
	methods: {
        addInfo(evt){

            let form = this.$refs[`infoRequestForm${this.focusedTransfer[0].transfer_id}`][0]

            if(evt.type == undefined || evt.field == undefined || evt.type == '' || evt.field == ''){                
                form.localError = 'The field and if it is missing or incorrect must be selected'
            }else{
                if(evt.type == 'missing'){
                    if(this.infoMissing.length >= 10)
                    { form.localError = 'There can be a maximum of 10 missing fields'}
                    else{
                        let obj = { code: evt.field }
                        if(evt.message && evt.message.length > 0)
                            obj['message'] = evt.message;

                        this.infoMissing.push(obj);
                        form.clear();
                        form.setValue('type', 'missing');
                    }
                }else{
                    if(this.infoIncorrect.length >= 10)
                    { form.localError = 'There can be a maximum of 10 incorrect fields'}
                    else{
                        let obj = { code: evt.field }
                        if(evt.message && evt.message.length > 0)
                            obj['message'] = evt.message;

                        this.infoIncorrect.push(obj);
                        form.clear();
                        form.setValue('type', 'incorrect');
                    }
                }
            }
        },
        dateChange(evt){
            this.date = evt.target.value
            this.fetchTransfers()
        },
        displayDate(raw){
            let utcDate = new Date(raw)
            let options = {
                timeZoneName: 'short',
                year: 'numeric',
                month: 'numeric', 
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }
            let localDate = new Intl.DateTimeFormat(navigator.language, options).format(utcDate);
            return localDate;
        },
        async waitForSettlementDate(){
            let timeout = 10000
            let interval = 100

            const startTime = Date.now();
            return new Promise((resolve, reject) => {
                const checkCondition = () => {
                    if(this.appStore.settlementDate){
                        resolve(this.appStore.settlementDate);
                    } else if(Date.now() - startTime >= timeout){
                        reject(new Error("Timeout waiting for settlementDate"));
                    }else{
                        setTimeout(checkCondition, interval);
                    }                    
                };
                checkCondition();
            });

        },
        async fetchTransfers(){
            try{

                //If date not set due to initial screen load, use settlement date
                if(this.date == '')
                    this.date = await this.waitForSettlementDate();

                let dataString = `settlement_date=${this.date}&page=${this.page}`;

                if(!this.userStore.isAmiAdmin){
                    dataString += `&participant_id=${this.userStore.participantIds[0]}`
                }

                let resp = await this.$fnapi.get(`/cct/query?${dataString}`)       

                this.focusedTransfer = {}
                this.focusedMessage = {}
                this.transfers = resp.data.items
                this.page = resp.data.page
                this.pages = resp.data.pages
                this.total = resp.data.total
                this.recordsLoading = false

            }catch(resp){
                console.log('fetchTransfers catch: ', resp);
                this.recordsLoading = false
            }
        },
        async fetchTransferDetails(id){
            try{
                let resp = await this.$fnapi.get(`/cct/messages/${id}`) 
                this.focusedTransfer = resp.data

            }catch(resp){
                console.log('resp catch: ', resp);
            }

        },
        fieldTypeChanged(evt){
            this.$refs[`infoRequestForm${this.focusedTransfer[0].transfer_id}`][0].values['type'] = '';

            if(evt == 'missing')
                this.infoFieldType = 'missingInfo'
            else if(evt == 'incorrect')
                this.infoFieldType = 'incorrectInfo'
            else
                this.infoFieldType = 'select'
        },
        nextPage(){
            this.page++;
            this.fetchTransfers();
        },
        previousPage(){
            this.page--;
            this.fetchTransfers();
        },
        removeField(fieldId, list){
            for(var ii = 0; ii < list.length; ii++){
                if(list[ii].code == fieldId){
                    list.splice(ii, 1);
                    break;
                }
            }
        },
        duplicateRequestSubmit(vals){   
            let data = {
                transfer_id: this.focusedTransfer[0].transfer_id,
                "possible_duplicate": true
            }
            this.$fnapi.post('/cct/information-request', data).then(r=>{
                    console.log('r: ', r);
                    this.mode = 'requestSent'
            })
            .catch((ex) => {  
                console.log('---DUPLICATE REQUEST ERROR---')    
                console.log('Data: ', data)          
                console.log(ex.response.data)       
                
                let error = 'There was an error submitting your request';
                if(ex.response && ex.response.data && ex.response.data.detail&& ex.response.data.detail[0])
                    error = ex.response.data.detail[0].msg;

                if(this.$refs[`duplicateForm${this.focusedTransfer[0].transfer_id}`]){
                    this.$refs[`duplicateForm${this.focusedTransfer[0].transfer_id}`][0].localError = error
                } else{
                    console.log('no ref');
                }
            }); 
        },
        infoRequestSubmit(vals){   

            let data = {
                transfer_id: this.focusedTransfer[0].transfer_id,
                "missing_info": this.infoMissing,
                "incorrect_info": this.infoIncorrect,
                "possible_duplicate": false
            }

            this.$fnapi.post('/cct/information-request', data).then(r=>{
                    console.log('r: ', r);
                    this.infoMissing = []
                    this.infoIncorrect = []
                    this.mode = 'requestSent'
            })
            .catch((ex) => {  
                console.log('---INFO REQUEST ERROR---')    
                console.log('Data: ', data)          
                console.log(ex.response.data)          
                
                if(this.$refs[`infoRequestForm${this.focusedTransfer[0].transfer_id}`]){
                    this.$refs[`infoRequestForm${this.focusedTransfer[0].transfer_id}`][0].localError = ex.response.data.detail[0].msg
                }

            }); 
        },
        returnRequestSubmit(vals){   
                
            let data = {
                transfer_id: this.focusedTransfer[0].transfer_id,
                reason_code: vals.code
            };
            if(vals.reason.length > 0)
                data['reason_text'] = vals.reason;
            let url = this.focusedTransfer[0].flags['ROLE_DEBTOR'] ? '/cct/return-request' : '/cct/return';
            
            this.$fnapi.post(url, data).then(r=>{
                    console.log('r: ', r);
                    this.mode = 'requestSent'
            })
            .catch((ex) => {  
                console.log('---CCT RETURN REQUEST ERROR---')    
                console.log('Data: ', data)  
                console.log('ex: ', ex);        
                
                if(this.$refs[`returnRequestForm${this.focusedTransfer[0].transfer_id}`] && this.$refs[`returnRequestForm${this.focusedTransfer[0].transfer_id}`][0]){
                    this.$refs[`returnRequestForm${this.focusedTransfer[0].transfer_id}`][0].localError ='There was an error submitting your request: ' + ex.message;
                }
                console.log(ex)          
            }); 
        }
	},
    mounted(){
        this.fetchTransfers();
    },
	created(){
        document.title = 'Transfers-FedNow';
	},
    setup(){
        const transfers = ref([])
        const mode = ref('')
        const page = ref(1)
        const pages = ref(1)
        const total = ref(0)
        const date = ref('')
        const recordsLoading = ref(true)
        const focusedTransfer = ref({})
        const focusedMessage = ref({})
        const infoMissing = ref([])
        const infoIncorrect = ref([])
        const infoFieldType = ref('select') 

        return {
            date,
            focusedMessage,
            focusedTransfer,
            infoIncorrect,
            infoMissing,
            infoFieldType,
            mode,
            page,
            pages,
            recordsLoading,
            total,
            transfers
        }
    }
}
</script>
