<template>
  <div class="max-w-96 mx-auto pt-10">
    <Form title="Login" icon="user" 
    ref="form"
      @submit="loginSubmit"
      :autoFocus="true"
      :fields="[{id: 'email', label: 'Email', initialValue: '', type: 'text', icon: 'envelope'}, 
        {id: 'password', label: 'Password', type: 'password', icon: 'lock-closed'}]"
      />
  </div>
</template>

<script>
// @ is an alias to /src
import Form from '@/components/fnui/Form.vue'

export default {
  name: 'Login',
  components: {
    Form
  },
  created(){    
    document.title = 'FedNow';
  },
  methods: {
    loginSubmit(vals){
				this.$fnapi.login(vals['email'], vals['password']).catch((e)=>{
						console.log("caught login error", e);
                        this.$refs.form.localError = e;
				});
    }
  }   
}
</script>
