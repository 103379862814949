<!-- Button -->
<template>
    <div :class="`fnui-${theme} ${size == 'lg' ? 'p-3' : size == 'xs' ? 'px-2 py-1' : 'p-2'} ${buttonHeight} duration-150
        ${rounded ? 'rounded-' + rounded : 'rounded'} 
        ${isVert ? 'flex-col' : 'flex-row'} flex cursor-pointer ${justify} items-center leading-4`">
        <Icon v-if="icon" :id="icon" :class="`${iconSize} 
            ${iconAfterLabel ? 'order-2 ml-2' : ''} 
            ${!isVert && label && size == 'lg' ? '' : !isVert && label ? 'mr-1' : ''} flex-shrink-0`" />
        <span v-if="!desc && label" :class="`${!isVert && size != 'xs' ? 'ml-2' : ''} uppercase font-bold`">{{label}}</span>
        <div v-else-if="desc" class="flex flex-col">
            <div class="uppercase font-bold">{{label}}</div>
            <div class="text-sm">{{desc}}</div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/fnui/Icon.vue";

import { ref } from "vue";

export default {
  components: {
    Icon
  },
  props: {
    label: String,
    desc: String,
    icon: String,
    iconAfterLabel: Boolean,
    isVert: Boolean,
    justify: {
        type: String,
        default: 'justify-center'
    },
    theme: {
        type: String,
        default: 'button' //button, submit, cancel, disabled
    },
    rounded: String,
    size: {
        type: String,
        default: 'lg' //lg, sm, xs
    }
    },
  computed:{
    buttonheight(){
        switch(this.$props.size){
            case 'large':
            case 'lg':
                return 'h-12';

            case 'small': 
            case 'sm': 
                return 'h-8';

            case 'xs': 
                return 'h-6';

            default: 
                return 'h-10';
        }
    },
    iconSize(){
        switch(this.$props.size){
            case 'large':
            case 'lg':
                return 'h-8 w-8';

            case 'small': 
            case 'sm':
                return 'h-6 w-6';
                
            case 'xs': 
                return 'h-5 w-5';

            default: 
                return 'h-8 w-8';
        }
    }
  },
  methods: {
  },
  setup(props, { emit }) {
    
    return { 
    };
  }
};
</script>
