<template>
    <div class="flex flex-col">
        <div v-for="check in passwordChecks" :key="check.label" class="flex items-center py-1">
            <div :class="`${check.pass ? 'bg-green-200 text-green-700' : 'bg-red-200 text-red-700'} rounded-lg mr-2`">
                <Icon :id="check.pass ? 'check' : 'x'" class="h-5 w-5" />                            
            </div>
            <span>{{check.label}}</span>
        </div>
    </div>  
</template>

<script>

import Icon from '@/components/fnui/Icon.vue'

export default {
    components: {
        Icon
    },
    computed:{
        isValidLength(){
            return (this.$props.pass1.length >= this.$props.minLength);
        },
        isValidMatch(){
            return (this.$props.pass1 == this.$props.pass2 && this.$props.pass1.length >= 1);
        },
        isValidUpper(){
            let upperFound = 0;
            let str = this.$props.pass1 + ""    
            let upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(upper.indexOf(char) != -1){
                    upperFound++;
                }
            }
            return upperFound >= this.$props.minUpper            
        },
        isValidLower(){
            let lowerFound = 0;
            let str = this.$props.pass1 + ""    
            let lower = 'abcdefghijklmnopqrstuvwxyz'

            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(lower.indexOf(char) != -1){
                    lowerFound++;
                }
            }
            return lowerFound >= this.$props.minLower            
        },
        isValidDigits(){
            let numbersFound = 0;
            let str = this.$props.pass1 + ""                    
            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(!isNaN(char)){
                    numbersFound++;
                }
            }
            return numbersFound >= this.$props.minDigits
        },
        isValidSpecial(){
            let specialFound = 0;
            let str = this.$props.pass1 + ""    
            let special = this.$props.specialChars

            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(special.indexOf(char) != -1){
                    specialFound++;
                }
            }
            return specialFound >= this.$props.minSpecial 
        },
        passwordChecks(){
            let checks = [
                { label: this.isValidMatch ? 'Passwords Match' : 'Passwords Must Match', pass: this.isValidMatch },
                { label: this.isValidLength ? 'Minimum Length Met' : 'At least '+this.minLength+' characters must be used', pass: this.isValidLength }];

            if(this.$props.minSpecial > 0)
                checks.push({ label: this.isValidSpecial ? 'At least '+this.$props.minSpecial+' special character used' : 'At least '+this.$props.minSpecial+' special character must be used: '+ this.specialCharsDisplay, pass: this.isValidSpecial })
                
            if(this.$props.minDigits > 0)
                checks.push({ label: this.isValidDigits ? 'At least '+this.$props.minDigits+' numbers used' : 'At least '+this.$props.minDigits+' numbers must be used', pass: this.isValidDigits })
            
            if(this.$props.minUpper > 0)
                checks.push({ label: this.isValidUpper ? 'At least '+this.$props.minUpper+' upper case letters used' : 'At least '+this.$props.minUpper+' upper case letters must be used', pass: this.isValidUpper })
            
            if(this.$props.minLower > 0)
                checks.push({ label: this.isValidLower ? 'At least '+this.$props.minLower+' lower case letters used' : 'At least '+this.$props.minLower+' lower case letters must be used', pass: this.isValidLower })
                
            return checks;
        },
        specialCharsDisplay(){
            let display = '';
            for(var chara in this.$props.specialChars){
                if(chara <= 10)
                    display += this.$props.specialChars.charAt(chara) + ' ';
            }
            return display
        }
    },
    methods: {        
        isValidPass(){
            return this.isValidPass && this.isValidLength && this.isValidMatch && this.isValidUpper && this.isValidLower && this.isValidSpecial;
        }
    },
    props:{
        pass1: {
            type: String,
            default: ''
        },
        pass2: {
            type: String,
            default: ''
        },
        minLength: {
            type: Number,
            default: 8
        },
        minUpper: {
            type: Number,
            default: 1
        },
        minLower: {
            type: Number,
            default: 0
        },
        minDigits: {
            type: Number,
            default: 1
        },
        minSpecial: {
            type: Number,
            default: 1
        },
        specialChars: {
            type: String,
            default: '!@#$%^&*()?_-'
        }
    },
    setup(){
        return {
        }
    }
}
</script>