<template>
    <Table title="Participants" class="w-full max-w-screen-sm" :recordsLoading="!recordsLoaded" :hasQuickFilter="true" @update:quickFilter="updateFilter" >
        <div v-for="(p, key) in participants" :key="key" 
            :class="`${filter.length > 0 && p.name.toLowerCase().indexOf(filter.toLowerCase()) == -1 && p.rtn.toLowerCase().indexOf(filter.toLowerCase()) == -1 ? 'hidden' : ''} py-4 px-2 flex justify-between border-b`">
            <div class="flex flex-col items-start">
                <div class="font-bold flex flex-row items-center" :title="p.online ? 'Online' : 'Offline'">
                    <div :class="`${p.online ? 'fnui-approved' : 'fnui-denied'} h-6 w-6 rounded-sm mx-1`"><Icon :id="p.online ? 'check' : 'x-mark'" /></div><span>{{p.name}}</span>
                </div>
                <div class="pl-8 -top-1 relative font-mono">RTN: {{p.rtn}}</div>
            </div>
            <div class="flex flex-col items-end">
                <div class="flex"><div v-for="s in p.services" class="mx-2 font-mono" :key="s">{{s}}</div></div>
            </div>
        </div>
    </Table>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

import { ref } from 'vue'

import Icon from '@/components/fnui/Icon.vue'
import Pages from '@/components/fnui/Pages.vue'
import Table from '@/components/fnui/Table.vue'

export default {
    name: 'Participants',
    components: {
        Icon,
        Pages,
        Table
    },
	computed: {
		...mapStores(useUserStore),
  },
	methods: {
        async fetchParticipants(){
            try{
                let dataString = ``;
                let resp = await this.$fnapi.get(`/participants${dataString}`)       

                console.log('fetchParticipants.resp: ', resp);
                this.participants = resp.data
                this.recordsLoaded = true

            }catch(resp){
                console.log('resp catch: ', resp);
                this.recordsLoaded = true
            }
        },
        updateFilter(val){
            this.filter = val
        }
	},
	created(){
        document.title = 'Participants-FedNow';
        this.fetchParticipants();
	},
    setup(){
        const participants = ref([])
        const recordsLoaded = ref(false)
        const filter = ref('')

        return { filter, participants, recordsLoaded };
    }
}
</script>
