<template>
  <div class="w-full max-w-screen-sm">
    <Notice v-if="notice.notice" :notice="notice.notice" :icon="notice.icon" :theme="notice.theme" class="mb-2" :showClose="true" @close="notice = {}" />
    <Form title="Update Password" icon="user" ref="updatePassForm" @submit="submitUpdateUser"
        @pass1Changed="(e) => { pass1 = e;}" @pass2Changed="(e) => { pass2 = e;}"
        :fields="[  { icon: 'lock', type: 'password', label: 'Current Password', id: 'current' }, 
                    { icon: 'lock', type: 'password', label: 'New Password', id: 'password', changeEmit: 'pass1Changed' }, 
                    { icon: 'lock', type: 'password', label: 'Confirm New Password', id: 'pass2', changeEmit: 'pass2Changed' }  ]">
        <PasswordChecker ref="passCheck" class="mx-5"
            :pass1="pass1"
            :pass2="pass2"
            :minLength="10"
            :minUpper="1"
            :minLower="1"
            :minDigits="1"
            :minSpecial="1" />
    </Form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

import Button from '@/components/fnui/Button.vue'
import Display from '@/components/fnui/Display.vue'
import Form from '@/components/fnui/Form.vue'
import Header from '@/components/fnui/Header.vue'
import Icon from '@/components/fnui/Icon.vue'
import Notice from '@/components/fnui/Notice.vue'
import PasswordChecker from '@/components/fnui/PasswordChecker.vue'
import Table from '@/components/fnui/Table.vue'

export default {
    name: 'Users',
    components: {
        Button,
        Display,
        Form,
        Header,
        Icon,
        Notice,
        PasswordChecker,
        Table
    },
	computed: {
		...mapStores(useUserStore),
  },
	methods: {
        // Fetch current system date and save in store 
        async fetchUserInfo(){
            try{
                let resp = await this.$fnapi.get(`/user/me`)
                console.log('resp: ', resp)
                this.user = resp.data
            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },   
        async submitUpdateUser(vals){    

            if(!this.$refs.passCheck.isValidPass()){
                this.$refs[`updatePassForm`].localError = 'Invalid password, check requirement list.'
                return;
            }   

            let data = {
                password: vals.password,
                current_password: vals.current
            };
            this.$fnapi.patch(`/user/me`, data).then(r=>{
                this.notice = { notice: 'User password successfully updated', theme: 'news', icon: 'lock' }
                this.$refs['updatePassForm'].clear();
            })
            .catch((ex) => {  
                console.log('ex: ', ex)         
                let form = this.$refs[`updatePassForm`];
                
                if(ex && ex.response && ex.response.data && ex.response.data.detail)
                    form.localError = ex.response.data.detail[0].msg
                else
                    form.localError = 'There was an error updating the user'
            }); 
        },
	},
	created(){
        document.title = 'User-FedNow';
        this.fetchUserInfo()
	},
    setup(){
        const notice = ref({})
        const user = ref([])
        const pass1 = ref('')
        const pass2 = ref('')

        return {
            notice,
            pass1,
            pass2,
            user
        }

    }
}
</script>
