<!-- Header: Header of Forms and Tables -->
<template>
    <div class="flex flex-col cursor-default">
        <div :class="`fnui-${theme} ${hasRoundedTop ? 'md:rounded-t-lg ' : ''} ${isAlone ? 'border-b-2 md:rounded-b-lg' : ''}
            w-full font-bold uppercase px-2 py-1 flex flex-row items-center justify-between pr-1`">
            <Icon v-if="icon" :id="icon" :class="`${iconSize} flex-shrink-0 ml-1`" />
            <span :class="`${titleSize} ${titlePadding} whitespace-nowrap`">{{title}}</span>
            <div class="flex-grow" />            
            <div v-if="!isVertical && hasQuickFilter" title="Filter" :class="`${theme == 'secondary' ? 'px-1' : 'p-1' } flex flex-row items-center rounded border fnui-content max-w-96 my-1 mr-2 font-normal`">
                <Icon id="search" class="h-4 w-4 mx-1 flex-shrink-0" @click="this.$refs.quickfilter.focus()" />
                <input :placeholder="quickFilterText ? quickFilterText : 'Filter'" class="flex-grow 4 rounded-sm text-right" ref="quickfilter" v-model="filter" 
                    @input="updateQuickFilter" @keydown.esc="clearFilter" />
            </div>
            <div v-if="!isVertical && buttons" class="flex flex-row items-center">
                <Button v-for="b in buttons" :key="b.emit" size="small" class="border" :theme="theme == 'secondary' ? 'secondary-button' : theme"
                    :label="b.label" @click="b.emitDirect ? emit(b.emitDirect) : emit('buttonClick', b.emit);" :icon="b.icon" />
            </div>
            <slot class="pr-1" />
            <Button v-if="showFilter" @click="this.$emit('toggleFilter')" :title="$store.state.lang.filter_records" icon="filter"
                 rounded="none"
                 :theme="theme" class="mx-1" size="small" />
            <Button v-if="showCsv" @click="this.$emit('saveCsv')" :title="$store.state.lang.csv_download" icon="document-arrow-down"
                 rounded="none"
                 :theme="theme" class="mx-1" size="small" />
            <Button v-if="showClose" @click="this.$emit('close')" icon="x" :theme="theme" size="small" :title="closeTitle" />
        </div>
        <div v-if="desc" 
            :class="`fnui-secondary py-1 w-full uppercase border-l-2 border-r-2 border-b px-2 text-sm text-center flex flex-row justify-center leading-4 font-mono items-center`">
            <Icon v-if="descIcon" :id="descIcon" class="h-5 w-5 mr-1" />
            <span>{{desc}}</span>
        </div>            
        <div v-if="isVertical && buttons" class="flex flex-col">
            <Button v-for="b in buttons" theme="secondary" rounded="none" :icon="b.icon" :label="b.label" :key="b.label" 
                @click="b.emitDirect ? emit(b.emitDirect) : emit('buttonClick', b.emit);" />
        </div>
        <div v-if="isVertical && hasQuickFilter" title="Filter" 
            class="flex flex-row items-center fnui-secondary font-normal border-2 py-1 pl-1 pr-2 border-t">
            <Icon id="search" class="h-4 w-4 mx-1 flex-shrink-0" @click="this.$refs.quickfilter.focus()" />
            <input class="flex-grow 4 rounded-sm text-center" ref="quickfilter" v-model="filter" 
            :placeholder="quickFilterText ? quickFilterText : 'Filter'" @input="updateQuickFilter" @keydown.esc="clearFilter" />
        </div>
    </div>
</template>

<script>
import Icon from "@/components/fnui/Icon.vue";
import Button from "@/components/fnui/Button.vue";

import { ref } from "vue";

export default {
  components: {
    Button,
    Icon
  },
  props: {
    closeTitle: { type: String, default: 'Close' },
    icon: String,
    isVertical: Boolean, /* Changes look of quickfilter, buttons */
    desc: String,
    descIcon: String,
    isAlone: Boolean,
    title: String,
    hasQuickFilter: Boolean,
    hasRoundedTop: {
        type: Boolean,
        default: true,
    },
    buttons: Object,
    quickFilterText: String,
    showCsv: Boolean,
    showClose: Boolean,
    showFilter: Boolean,
    theme: {
        type: String,
        default: 'header' // header or content for more subtle header
    },
    verticalButtons: Object
  },
  computed:{
    iconSize(){
        switch(this.$props.theme){
            case 'header':
            default: 
                return 'h-8 w-8';
            
            case 'content':         
                return 'h-6 w-6';

            case 'secondary':              
                return 'h-5 w-5';  
        }
    },
    titlePadding(){        
        switch(this.$props.theme){
            case 'header':
            default: 
                return 'p-2';            
            case 'content':
                return 'px-2 py-1';
            case 'secondary':                
                return 'px-2 py-1';
        }
    },
    titleSize(){
        switch(this.$props.theme){
            case 'header':
            default: 
                return 'text-lg';            
            case 'content':
                return 'text-base';
            case 'secondary':                
                return 'text-sm';
        }

    }
  },
  methods: {
    clearFilter(){
        this.filter = '';
        this.updateQuickFilter();
    },
    updateQuickFilter(){
        console.log('header.updateQuickFilter');
        this.$emit('update:quickFilter', this.filter);
    }
  },
  setup(props, { emit }) {
    const filter = ref('')
    return { 
        emit,
        filter
    };
  }
};
</script>
