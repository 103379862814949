import { createRouter, createWebHistory } from 'vue-router'
import CasesView from '../views/CasesView.vue'
import HomeView from '../views/HomeView.vue'
import ReportsView from '../views/ReportsView.vue'
import ParticipantsView from '../views/ParticipantsView.vue'
import SendView from '../views/SendView.vue'
import StatusView from '../views/StatusView.vue'
import UserView from '../views/UserView.vue'
import UsersView from '../views/UsersView.vue'
import CctTransfersView from '../views/CctTransfersView.vue'

const routes = [
    {
      path: '/',
      name: 'home',
      component: StatusView
    },
    {
      path: '/cases',
      name: 'cases',
      component: CasesView
    },
    {
      path: '/reports',
      name: 'reports',
      component: ReportsView
    },
    {
      path: '/participants',
      name: 'participants',
      component: ParticipantsView
    },
    {
      path: '/send',
      name: 'send',
      component: SendView
    },
    {
      path: '/user',
      name: 'user',
      component: UserView
    },
    {
      path: '/users',
      name: 'users',
      component: UsersView
    },
    {
      path: '/cct',
      name: 'cctTransfers',
      component: CctTransfersView
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
