<template>
  <div class="w-full max-w-screen-md">
    <!-- Send Money Form-->
    <Form v-if="mode == 'send'" title="Customer Credit Transfer" icon="arrows-left-right" :columns="3" :autoFocus="true"
        @submit="goToReview"
        :error="sendError"
        submitText="Transfer"
        :validate="true"
        :fields="[
            { id: 'amount', initialValue: transferDetails.amount, width: 'w-full',label: 'Amount to Send', type: 'money', icon: 'dollar' },
            { id: 'fromHeader', width: 'w-full', label: 'Transferring From', type: 'subheader', icon: 'arrow-right' },
            { id: 'fromRtn', initialValue: initialFromRtn, width: 'w-full', label: 'From RTN', type: 'text', icon: 'building-office' },
            { id: 'fromAccountId', initialValue: transferDetails.fromAccountId, width: 'w-1/2', label: 'From Account ID', type: 'text', icon: 'hashtag' },
            { id: 'fromName', initialValue: transferDetails.fromName, width: 'w-1/2',label: 'From Name', type: 'text', icon: 'identification' },
            { id: 'toHeader', width: 'w-full', label: 'Transferring To', type: 'subheader', icon: 'arrow-left' },
            { id: 'toRtn', initialValue: transferDetails.toRtn, width: 'w-full', label: 'To RTN', type: 'text', icon: 'building-office' },
            { id: 'toAccountId', initialValue: transferDetails.toAccountId, width: 'w-1/2',label: 'To Account ID', type: 'text', icon: 'hashtag' },
            { id: 'toName', initialValue: transferDetails.toName, width: 'w-1/2',label: 'To Name', type: 'text', icon: 'identification' }
        ]" />
    <!-- Review Transfer -->
    <div v-else-if="mode == 'review'">
        <Form title="Confirm Transfer Details" :showCancel="true" @cancel="mode = 'send'" submitText="Transfer" @submit="transferSubmit">
            <div class="w-full flex flex-row">
                <Table title="Transfer From" theme="secondary" class="w-2/5">
                    <Display label="Name"  :value="transferDetails.fromName" />
                    <Display label="RTN" :value="transferDetails.fromRtn" />
                    <Display label="Accout ID"  :value="transferDetails.fromAccountId" />
                </Table>
                <div class="w-1/5 flex flex-col items-center justify-center">
                    <Display label="Transfer Amount"  :value="`$${parseFloat(transferDetails.amount).toFixed(2)}`" />
                    <div class="mx-auto h-16 w-16 fnui-secondary rounded-full p-2"><Icon id="arrow-right" /></div>
                </div>
                <Table title="Transfer To" theme="secondary" class="w-2/5">
                    <Display label="Name"  :value="transferDetails.toName" />
                    <Display label="RTN" :value="transferDetails.toRtn" />
                    <Display label="Accout ID"  :value="transferDetails.toAccountId" />
                </Table>
            </div>
        </Form>
    </div>
    <!-- Transfer Complete -->
    <div v-else-if="mode == 'complete'">
        <Table title="Transfer Complete">
            <div class="w-full px-5 py-2">
                <Notice v-if="completeStatus == 'ACSC'" notice="Transfer Complete" theme="news" icon="check" />
                <Notice v-else-if="completeStatus == 'ACWP'" notice="Accepted Without Posting" />
                <Notice v-else-if="completeStatus == 'ACCC'" notice="Accepted Complete" />
            </div>
            <div class="py-5">Transfer of <span class="font-bold">${{parseFloat(transferDetails.amount).toFixed(2)}}</span> from <span class="font-bold">{{transferDetails.fromName}} {{transferDetails.fromAccountId}}</span> (RTN: {{transferDetails.fromRtn}}) <span class="font-bold">to {{transferDetails.toName}} {{transferDetails.toAccountId}}</span> (RTN: {{transferDetails.toRtn}})</div>
            <div class="flex flex-row justify-around w-full mb-2">
                <Button label="Run Another Transfer" @click="reset" />
                <Button label="View Transfers" @click="this.$router.push('/cct')"/>
            </div>
        </Table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

import Button from '@/components/fnui/Button.vue'
import Display from '@/components/fnui/Display.vue'
import Form from '@/components/fnui/Form.vue'
import Header from '@/components/fnui/Header.vue'
import Icon from '@/components/fnui/Icon.vue'
import Notice from '@/components/fnui/Notice.vue'
import Table from '@/components/fnui/Table.vue'

export default {
    name: 'Send',
    components: {
        Button,
        Display,
        Form,
        Header,
        Icon,
        Notice,
        Table
    },
	computed: {
		...mapStores(useUserStore),
        initialFromRtn(){
            if(this.userStore.participantIds && this.userStore.participantIds[0]){
                return this.userStore.participantIds[0]
            }
            return '';
        }
  },
	methods: {
        /* Step 1 submit, go to trasnfer review */
        goToReview(vals){
            this.mode = 'review'
            this.sendError = '';
            this.transferDetails = vals;
        },
        reset(){
            this.mode = 'send'
            this.transferDetails = {}
            this.completeStatus = '';
        },
        transferSubmit(){
            let data = {
                from_rtn: this.transferDetails.fromRtn,
                from_account_id: this.transferDetails.fromAccountId,
                from_name: this.transferDetails.fromName,
                to_rtn: this.transferDetails.toRtn,
                to_account_id: this.transferDetails.toAccountId,
                to_name: this.transferDetails.toName,
                amount: this.transferDetails.amount
            };
            
            this.$fnapi.post('/cct/send', data).then(r=>{
                    console.log('r: ', r);

                    this.completeStatus = r.data.status
                    //Check response status
                    if(this.completeStatus == 'RJCT' ||  this.completeStatus == 'BLCK'){
                        this.mode = 'send' 
                        if(r.data.reason_message)
                            this.sendError= r.data.reason_message
                        else if(r.data.reason_code)
                            this.sendError = 'There was an error with your transfer. Code '+ r.data.reason_code;
                        else
                            this.sendError = 'There was an error with your transfer.';
                        
                    }else {
                        this.mode = 'complete'
                    }
            })
            .catch((ex) => {  
                console.log('---TRANSFER ERROR---', ex)    
                console.log('Transfer Data: ', data)    
                if(ex.response && ex.response.data && ex.response.data.errors && ex.response.data.errors[0]){
                    this.sendError = ex.response.data.errors[0].detail
                }else{
                    this.sendError = 'There was an error processing your request, please try again later.'
                }
            });    
        }
	},
	created(){
        document.title = 'Send Money-FedNow';
	},
    setup(){

        const transferDetails = ref({
            amount: '',
            fromRtn: '',
            fromAccountId: '',
            fromName: '',
            toRtn: '',
            toAccountId: '',
            toName: ''});

        const mode = ref('send')
        const sendError = ref('')
        const completeStatus = ref('')
        return {
            completeStatus,
            mode,
            sendError,
            transferDetails
        }

    }
}
</script>
