<!-- Table -->
<template>
    <div class="flex flex-col">
        <Header :title="title" 
            :theme="theme" 
            :desc="desc" 
            :descIcon="descIcon" 
            :hasQuickFilter="hasQuickFilter" :showCsv="showCsv"
            :hasRoundedTop="isRounded"
            :isVertical="isVertical"
            :buttons="buttons"
            :icon="icon" 
            :showClose="showClose"
            :closeTitle="closeTitle"
            @close="this.$emit('close')"
            @buttonClick="e => { this.$emit(e); }"
            @saveCsv="this.$emit('saveCsv')"
            @update:quickFilter="updateQuickFilter" 
            @toggleFilter="filterOpen = !filterOpen" 
            :showFilter="hasFullFilter">            <slot name="header" />
        </Header>
        <Pages v-if="page && pages" :page="page" :pages="pages" :total="total" @next="this.$emit('nextPage')" @previous="this.$emit('previousPage')" />
        <div v-if="recordsLoading || isLoading" class="text-center text-xs py-1 fnui-secondary py-2 border-t border-b italic flex flex-row items-center justify-center">
            <Icon class="h-4 w-4 mr-1" id="loading" /><p>Records Loading</p>
        </div>
        <!--
        <Filter v-if="filterOpen" :filterBy="filterBy" @close="filterOpen = false" 
            @update:filter="updateFilter" />-->
        <div :class="`${columns > 1 ? 'flex-row flex-wrap' : 'flex-col'} ${isRounded ? 'rounded-b-lg' : ''} fnui-content flex-grow justify-start 
            sfnui-content flex border-r-2 border-l-2 border-b-2 `">            
            <slot class="pt-2" />
            <Pages v-if="page && pages" :page="page" :pages="pages" :total="total" @next="this.$emit('nextPage')" @previous="this.$emit('previousPage')" />
        </div>
    </div>
</template>

<script>

import Header from "@/components/fnui/Header.vue";
import Icon from "@/components/fnui/Icon.vue";
import Pages from "@/components/fnui/Pages.vue";

import { ref, computed } from "vue";

export default {
  components: {    
    Header,
    Icon,
    Pages
  },
  props: {
    buttons: Object,
    closeTitle: String,
    desc: String,
    descIcon: String,
    title: String,
    icon: String,
    isRounded: { type: Boolean, default: true},
    isVertical: Boolean, /* Changes look of quickfilter, buttons */
    quickFilterValue: Object,
    hasQuickFilter: Boolean,
    hasFullFilter: Boolean,
    page: Number,
    pages: Number,
    total: Number,
    recordsLoading: Boolean,
    showClose: Boolean,
    showCsv: Boolean,
    columns: {
        type: Number,
        default: 1
    },
    filterBy: {            
        type: Object,
        default: []
    },
    theme: {
        type: String,
        default: 'header' // header or content
    }
  },
  computed: {
  },
  methods: {
    updateFilter(val){
        console.log('table.update filter: ', val);
        this.$emit('update:filter', val);
    },  
    updateQuickFilter(val){
        console.log('table.updateQuickFilter.val: ', val)
        this.$emit('update:quickFilter', val);
    }
  },
  setup(props, { emit }) {
        
    const filterOpen = ref(false);
    const isLoading = ref(false)

    return { 
        filterOpen,
        isLoading
    };
  }
};
</script>
