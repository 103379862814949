/**
 * Plugin to access AMI FedNow API
 */
import axios from "axios";
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'

function make_api(baseUrl){
	const appStore = useAppStore();
	const userStore = useUserStore();

	var HEADERS = {
		'X-Requested-With': 'XMLHttpRequest',
		'X-FN-UI-Ver': "TODO",
	}
	var api = axios.create({
		baseURL: baseUrl,
		withCredentials: true,
		headers: HEADERS, 
	});
	api.interceptors.request.use(function (config) {
		//HEADERS is copied on load so we have to set it on each request.
		if(HEADERS['Authorization']){
			config.headers.Authorization = HEADERS['Authorization'];
		}
		return config;
	});

    // Check for 401 response to trigger a logout
    api.interceptors.response.use(function (resp){ return resp; }, function (error){
        if(error.response && error.response.status == 401){
            api.logout();
        }
        return Promise.reject(error);
    });

	api.login = function(username, password){
		api.logout(); //logout first
		const params = new URLSearchParams();
		params.append("grant_type", "password");
		params.append("username", username);
		params.append("password", password);
		return api.post("auth/token", params).then(function(resp){
			localStorage.setItem("access_token", resp.data.access_token);
			HEADERS['Authorization'] = "Bearer " + resp.data.access_token;
			console.log("auth success", resp);
			api.loadUser(); //get user info
		}).catch((r)=>{
			if(r.response && r.response.data && r.response.data.detail){
				// A message about the error was sent so send that back
				throw new Error(r.response.data.detail);
			}
			throw new Error("Unable to authenticate");
		});
	};
	api.logout = function(){
		HEADERS["Authorization"] = null;
		localStorage.removeItem("access_token");
		userStore.$reset();
	};
	api.loadUser = function(){
		api.get("user/me").then((r)=>{
			userStore.setUser(r.data);
		}).catch(e=>{
			if(e.response && e.response.status == 401){
				//access_token is not valid
				api.logout();
				return;
			}
			throw e;
		}).finally(()=>{
			appStore.isLoading = false;
		});
	}
	api._initialAuthCheck = function(){
		let token = localStorage.getItem("access_token");
		if(token){
			HEADERS['Authorization'] = "Bearer " + token;
			console.log("token found");
			api.loadUser();
		}else{
			console.log("no token found");
			appStore.isLoading = false;
		}
	};
	api._initialAuthCheck();
	return api;
}

export default {
  install: (app, options) => {
	let url = process.env.VUE_APP_FN_API_URL;
	let fnapi = make_api(url);
    app.provide('fnapi', fnapi);
	app.config.globalProperties.$fnapi = fnapi;
	//for dev
	if(process.env.NODE_ENV == "development"){
		//Expose fnapi to the dev console for testing.
		window.fnapi = fnapi;
	}
  }
}
