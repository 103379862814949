<template>
  <div :style="customCSSVars">   
    <transition name="fade">
		<div v-if="appStore.isLoading">
			Loading...
		</div>
		<div v-else class="flex flex-row justify-between w-full">
            <SideNav class="fixed w-60" />
            <div v-if="userStore.isLoggedIn" class="m-2 mx-auto pl-64 w-full">
                <transition name="fade">
                    <router-view class="mx-auto" />
                </transition>
            </div>
			<LoginView v-else/>
		</div>
    </transition> 
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import LoginView from '@/views/LoginView'

import SideNav from '@/components/fnui/SideNav.vue'

export default {
  name: 'FedNow',
  components: {
	LoginView,
    SideNav
  },
  computed: {
		...mapStores(useAppStore, useUserStore),
    
		customCSSVars(){
      
            let theme = {
                navbarBgColor: '#184594',
                navbarTextColor: '#ffffff',
                navbarBorderColor: '#4277d4',
                navbarBgHoverColor: '#1b53b5',

                bodyBgTopColor: '#f3f4f6',
                bodyBgBottomColor: '#f3f4f6',
                bodyTextColor: '#444444',

                inputBgColor: '#fafafa',
                inputTextColor: '#444444',
                inputBorderColor: '#bababa',
                inputTextFocusColor: '#000000',
                inputNoteBgColor: '#e2e2e2',
                inputNoteTextColor: '#676767',

                inputDisabledBgColor: '#727272',
                inputDisabledTextColor: '#bfbfbf',
                inputDisabledBorderColor: '#727272',

                contentBgColor: '#f8f8f8',
                contentBgHoverColor: '#fcfae6',
                contentTextColor: '#444444',
                contentTextHoverColor: '#222222',
                contentBorderColor: '#ffffff',

                contentAltBgColor: '#dfdfdf',
                contentAltTextColor: '#555555',
                contentAltBorderColor: '#dedede',

                contentAltButtonBgColor: '#eaeaea',
                contentAltButtonBgHoverColor: '#fafafa',
                contentAltButtonBorder: '#cacaca',

                buttonBgColor: '#184594',
                buttonBgHoverColor: '#2759af',
                buttonTextColor: '#eeeeee',
                buttonTextHoverColor: '#ffffff',
                buttonBorderColor: '#2a63c6',

                buttonSubmitBgColor: '#1c9460',
                buttonSubmitBgHoverColor: '#20ae70',
                buttonSubmitTextColor: '#eeeeee',
                buttonSubmitTextHoverColor: '#ffffff',
                buttonSubmitBorderColor: '#20ae70',

                buttonCancelBgColor: '#ac3535',
                buttonCancelBgHoverColor: '#d04f4f',
                buttonCancelTextColor: '#eeeeee',
                buttonCancelTextHoverColor: '#ffffff',
                buttonCancelBorderColor: '#d04f4f',

                alertBgColor: '#c96161',
                alertBgHoverColor: '#dd7777',
                alertTextColor: '#ffeff0',
                alertBorderColor: '#cf4558',

                approvedBgColor: '#27a971',
                approvedTextColor: '#eeeeee',
                approvedBorderColor: '#218a5c',

                noticeBgColor: '#f3f377',
                noticeTextColor: '#1b1b1b',
                noticeBorderColor: '#eaea41'
            }
			return {
                '--navbar-bg': theme.navbarBgColor,
                '--navbar-text': theme.navbarTextColor,
                '--navbar-border': theme.navbarBorderColor,
                '--navbar-bg-hover': theme.navbarBgHoverColor,

                '--body-bg-top': theme.bodyBgTopColor,
                '--body-bg-bottom': theme.bodyBgBottomColor,
                '--body-text': theme.bodyTextColor,

                '--input-bg': theme.inputBgColor,
                '--input-text': theme.inputTextColor,
                '--input-border': theme.inputBorderColor,
                '--input-text-focus': theme.inputTextFocusColor,
                '--input-note-bg': theme.inputNoteBgColor,
                '--input-note-text': theme.inputNoteTextColor,

                '--disabled-bg': theme.inputDisabledBgColor,
                '--disabled-text': theme.inputDisabledTextColor,
                '--disabled-border': theme.inputDisabledBorderColor,

                '--content-bg': theme.contentBgColor,
                '--content-bg-hover': theme.contentBgHoverColor,
                '--content-text': theme.contentTextColor,
                '--content-text-hover': theme.contentTextHoverColor,
                '--content-border': theme.contentBorderColor,

                '--secondary-bg': theme.contentAltBgColor,
                '--secondary-text': theme.contentAltTextColor,
                '--secondary-border': theme.contentAltBorderColor,
                '--secondary-button-bg': theme.contentAltButtonBgColor,
                '--secondary-button-bg-hover': theme.contentAltButtonBgHoverColor,
                '--secondary-button-border': theme.contentAltButtonBorder,

                '--button-bg': theme.buttonBgColor,
                '--button-bg-hover': theme.buttonBgHoverColor,
                '--button-text': theme.buttonTextColor,
                '--button-text-hover': theme.buttonTextHoverColor,
                '--button-border': theme.buttonBorderColor,

                '--submit-bg': theme.buttonSubmitBgColor,
                '--submit-bg-hover': theme.buttonSubmitBgHoverColor,
                '--submit-text': theme.buttonSubmitTextColor,
                '--submit-text-hover': theme.buttonSubmitTextHoverColor,
                '--submit-border': theme.buttonSubmitBorderColor,

                '--cancel-bg': theme.buttonCancelBgColor,
                '--cancel-bg-hover': theme.buttonCancelBgHoverColor,
                '--cancel-text': theme.buttonCancelTextColor,
                '--cancel-text-hover': theme.buttonCancelTextHoverColor,
                '--cancel-border': theme.buttonCancelBorderColor,

                '--alert-bg': theme.alertBgColor,
                '--alert-bg-hover': theme.alertBgHoverColor,
                '--alert-text': theme.alertTextColor,
                '--alert-border': theme.alertBorderColor,

                '--approved-bg': theme.approvedBgColor,
                '--approved-text': theme.approvedTextColor,
                '--approved-border': theme.approvedBorderColor,

                '--notice-bg': theme.noticeBgColor,
                '--notice-text': theme.noticeTextColor,
                '--notice-border': theme.noticeBorderColor,
                                
						
			};
		}
  },
  methods: {
    // Fetch all codes and save in store
    async fetchCodes(){
        try{
            let resp = await this.$fnapi.get(`/codes`)
            this.appStore.codes = resp.data
        }catch(resp){
            console.log('resp catch: ', resp);
        }

        //Add info request response codes
        let infoStatusCodes = {
            NINF: { code: 'NINF', desc: '', name: 'No Further Information Available' },
            PDNG: { code: 'PDNG', desc: '', name: 'Information Request Pending' }
        }
        this.appStore.codes['info_status'] = infoStatusCodes

    },
    // Fetch all participants and save in store for easy RTN reference/lookup
    async fetchParticipants(){
        try{
            let dataString = ``;
            let resp = await this.$fnapi.get(`/participants${dataString}`)
            this.appStore.participants = resp.data
        }catch(resp){
            console.log('resp catch: ', resp);
        }
    },
    // Fetch current system date and save in store 
    async fetchSettlementDate(){
        console.log('fetchSettlementDate: ');
        try{
            let resp = await this.$fnapi.get(`/status`)
            this.appStore.settlementDate = resp.data.settlement_date;
        }catch(resp){
            console.log('resp catch: ', resp);
        }
    },
  },
  mounted(){
    this.fetchCodes();
    this.fetchSettlementDate();

    if(this.userStore.isLoggedIn && this.appStore.participants.length == 0)
        this.fetchParticipants()
  }
  
}

</script>

<style src="./assets/styles/smooth-label.css" />
<style src="./assets/styles/transitions.css" />
<style src="./assets/styles/fnui.css" />

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
