import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
	  isLoading: true,
      participants: [],
      codes: {},
      settlementDate: ''
  }),
})

