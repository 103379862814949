<template>
    <div class="fnui-content p-0 flex flex-col p-4 text-right border-b md:border-b-0 md:text-center">
        <div class="uppercase text-sm font-bold tracking-tighter leading-4">{{label}}</div>    
        <div class="text-3xl font-bold my-1 leading-7">{{value}}</div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: "Display",   
    components: {
    },
    props:{
        label: String,
        value: String
    },
    computed: {
    },
    methods:{
    },
    setup(){     
        
        return{
        }
    }
}

</script>
