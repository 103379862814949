<template>
  <div class="w-full max-w-screen-md">
    <Notice v-if="notice.notice" :notice="notice.notice" :icon="notice.icon" :theme="notice.theme" class="mb-2" :showClose="true" @close="notice = {}" />
    <Form v-if="showNewUserForm" title="Add New User" icon="user-plus" :showCancel="true" @cancel="showNewUserForm = false" 
        :autoFocus="true"
        :validate="true"
        ref="addUserForm"
        :columns="2"
        @submit="submitAddUser"
        :fields="addUserFields"/>
    <Table v-else ref="usersTable" title="Users" icon="users" class="w-full" @add="showNewUserForm = true" @nextPage="nextPage()" @previousPage="previousPage()" :buttons="[{ label: 'Add User', icon:'plus-circle', emit: 'add'}]"
        :page="page" :pages="pages" :total="total">
        <div class="w-full flex flex-col">
            <div v-for="user in users" :key="user.id" @click="focusedUser.id && focusedUser.id == user.id ? '' : fetchUser(user.id)"
                :class="`${focusedUser.id && focusedUser.id != user.id ? 'opacity-30' : 'opacity-100'} flex flex-col w-full border-b`">
                <!-- Focused Transfer header -->
                <Header v-if="focusedUser.id == user.id" icon="user" :title="`Focused User: ${focusedUser.email}`" :showClose="true" @close="focusedUser = {}" @click.stop.prevent class="w-full my-2" :hasRoundedTop="false" />
                <!-- Buttons Toolbar -->            
                <div v-if="focusedUser.id == user.id" class="w-full flex justify-start px-2 pb-2 gap-x-2">
                    <Button size="sm" theme="secondary-button" class="border w-1/3" label="Update Password" icon="lock" @click.stop.prevent="focusedUserMode = 'password'" />
                    <Button size="sm" theme="secondary-button" class="border w-1/3" label="Update Info" icon="document-text" @click.stop.prevent="focusedUserMode = 'info'" />
                    <Button size="sm" theme="cancel" class="border w-1/3" label="Delete" icon="trash" @click.stop.prevent="focusedUserMode = 'delete'" />
                </div>
                <!-- Update Password -->
                <Form v-if="focusedUserMode == 'password' && focusedUser.id == user.id" :columns="1" :validate="true" theme="secondary"
                    @submit="submitUpdatePassword" :ref="`updatePassForm${user.id}`"
                    :fields="[
                        { icon: 'lock', type: 'password', label: 'Password', id: 'password' }                       
                    ]"
                    class="mx-auto w-5/6 mt-2 mb-3" icon="lock" :autoFocus="true" title="Update User Password" :showCancel="true" @cancel="focusedUserMode = ''" submitText="Update Password" />
                <!-- Update Info -->
                <Form v-if="focusedUserMode == 'info' && focusedUser.id == user.id" :columns="2" :validate="true" theme="secondary" @submit="submitUpdateUser"
                    :ref="`updateUserForm${user.id}`"
                    :fields="[
                        { icon: 'envelope', type: 'email', label: 'Email', id: 'email', initialValue: focusedUser.email },
                        { type: 'checkbox', label: 'Disabled', id: 'disabled', initialValue: focusedUser.disabled },
                        { type: 'subheader', label: 'User Roles', width: 'w-full' },
                        { type: 'checkbox', label: 'FI Admin', id: 'role_fiadmin', initialValue: focusedUser.roles.indexOf('fiadmin') != -1 },
                        { type: 'checkbox', label: 'Teller', id: 'role_teller', initialValue: focusedUser.roles.indexOf('teller') != -1 } ,
                        { type: 'checkbox', label: 'Sender', id: 'role_sender', initialValue: focusedUser.roles.indexOf('sender') != -1 }                   
                    ]"
                    class="mx-auto w-5/6 mt-2 mb-3" icon="document-text" :autoFocus="true" title="Update User Info" :showCancel="true" @cancel="focusedUserMode = ''" submitText="Update User" />
                <!-- Confirm Delete -->
                <Form v-if="focusedUserMode == 'delete' && focusedUser.id == user.id" theme="secondary" @submit="deleteFocusedUser()"
                    class="mx-auto w-5/6 mt-2 mb-3" icon="trash" title="Are you sure you want to delete this user?" :showCancel="true" @cancel="focusedUserMode = ''" submitText="Delete User" />
                <!-- User Info-->
                <div :class="`${user.disabled ? 'fnui-alert' : !focusedUser.id ? 'fnui-content-hover cursor-pointer ' : 'fnui-content'} border-b px-2 py-4 flex flex-row items-center`">
                    <div class="w-12 flex flex-row items-center justify-center mr-2" title="FI ID"><Icon id="building-office" class="h-5 w-5 mr-1" />{{user.fi_id}}</div>
                    <div class="w-24 flex flex-row items-center" title="User ID"><Icon id="identification" class="h-5 w-5 mr-1" />(#{{user.id}})</div>                
                    <div class="flex flex-grow flex-row items-center" title="Email"><Icon id="envelope" class="h-5 w-5 mr-1" />{{user.email}}</div>
                    <div class="w-40 flex flex-row items-center " title="Roles"><Icon id="wrench-screwdriver" class="w-5 h-5 mr-1" /><div v-for="role in user.roles" class="mr-1" :key="role">{{role}}</div></div>
                    
                </div>
                <Header v-if="focusedUser.id == user.id" class="w-full" :hasRoundedTop="false" />
            </div>
        </div>
    </Table>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

import Button from '@/components/fnui/Button.vue'
import Display from '@/components/fnui/Display.vue'
import Form from '@/components/fnui/Form.vue'
import Header from '@/components/fnui/Header.vue'
import Icon from '@/components/fnui/Icon.vue'
import Notice from '@/components/fnui/Notice.vue'
import Table from '@/components/fnui/Table.vue'

export default {
    name: 'Users',
    components: {
        Button,
        Display,
        Form,
        Header,
        Icon,
        Notice,
        Table
    },
	computed: {
		...mapStores(useUserStore),
        addUserFields(){
            let fields = []

            fields.push(
            { icon: 'envelope', type: 'email', label: 'Email', id: 'email', width: 'w-full' },
            { icon: 'lock', type: 'password', label: 'Password', id: 'password', width: 'w-full' });

            if(!this.userSelf.fi_id)
                fields.push({ icon: 'building-office', type: 'text', label: 'FI ID', id: 'fi_id', width: 'w-full' })

            fields.push({ type: 'subheader', label: 'User Roles', width: 'w-full' },
            { type: 'checkbox', label: 'FI Admin', id: 'role_fiadmin' },
            { type: 'checkbox', label: 'Teller', id: 'role_teller' },
            { type: 'checkbox', label: 'Sender', id: 'role_sender' })
        
            return fields
    }
  },
	methods: {
        async deleteFocusedUser(){
            this.$fnapi.delete(`/user/${this.focusedUser.id}`).then(r=>{
                this.notice = { notice: 'User successfully deleted', theme: 'alert', icon: 'trash' }
                this.focusedUser = {};
                this.fetchUsers()
            })
            .catch((ex) => {  
                console.log('---DELETE USER ERROR---')    
                console.log('ex: ', ex)         
                if(ex && ex.response && ex.response.data && ex.response.data.detail)
                    this.$refs.addUserForm.localError = ex.response.data.detail[0].msg
                else
                    this.$refs.addUserForm.localError = 'There was an error deleting the user'
            }); 
        },
        // Fetch current system date and save in store 
        async fetchUsers(){
            try{
                this.users = [];
                this.$refs.usersTable.isLoading = true;
                let resp = await this.$fnapi.get(`/user/?fi=${this.fi}&page=${this.page}`)
                this.$refs.usersTable.isLoading = false;
                this.users = resp.data.items
                this.page = resp.data.page
                this.pages = resp.data.pages
                this.total = resp.data.total
            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
        // Fetch current system date and save in store 
        async fetchUser(id){
            try{
                let resp = await this.$fnapi.get(`/user/${id}`)
                this.focusedUser = resp.data
                this.focusedUserMode = ''
            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
        async fetchSelf(){
            try{
                let resp = await this.$fnapi.get(`/user/me`)
                this.userSelf = resp.data
            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
        nextPage(){
            this.page++;
            if(this.page > this.pages)
                this.page = this.pages;

            this.fetchUsers();
        },
        previousPage(){
            this.page--;
            if(this.page < 1) 
                this.page = 1;
            
            this.fetchUsers();
        },
        async submitAddUser(vals){            
            let roles = [];
            if(vals.role_teller)
                roles.push('teller')

            if(vals.role_fiadmin)
                roles.push('fiadmin')
            
            if(vals.role_sender)
                roles.push('sender')

            let fiId = vals.fi_id;
            if(this.userSelf.fi_id) fiId = this.userSelf.fi_id;

            let data = {
                email: vals.email,
                roles: roles,
                disabled: false,
                fi_id: fiId,
                password: vals.password
            };
            this.$fnapi.post('/user/', data).then(async r=>{
                this.notice = { notice: 'User "'+ r.data.email +'" successfully added', theme: 'news', icon: 'user-plus' }
                this.showNewUserForm = false

                //Delay so table ref isn't null                
                await new Promise(resolve => setTimeout(resolve, 1));  
                this.fetchUsers()
            })
            .catch((ex) => {  
                console.log('ex: ', ex)            
                if(ex && ex.response && ex.response.data && ex.response.data.detail)
                    this.$refs.addUserForm.localError = ex.response.data.detail[0].msg
                else
                    this.$refs.addUserForm.localError = 'There was an error adding the user'
            }); 

        },
        async submitUpdateUser(vals){            
            let roles = [];
            if(vals.role_teller)
                roles.push('teller')

            if(vals.role_fiadmin)
                roles.push('fiadmin')

            if(vals.role_sender)
                roles.push('sender')

            let data = {
                email: vals.email,
                roles: roles,
                disabled: vals.disabled === true
            };
            this.$fnapi.patch(`/user/${this.focusedUser.id}`, data).then(r=>{
                this.notice = { notice: 'User successfully updated', theme: 'news', icon: 'document-text' }
                this.focusedUserMode = ''
                this.focusedUser = r.data;
                this.fetchUsers()
            })
            .catch((ex) => {  
                console.log('ex: ', ex)         
                let form = this.$refs[`updateUserForm${this.focusedUser.id}`];
                if(form) form = form[0];                
                if(ex && ex.response && ex.response.data && ex.response.data.detail)
                    form.localError = ex.response.data.detail[0].msg
                else
                    form.localError = 'There was an error updating the user'
            }); 
        },
        async submitUpdatePassword(vals){   

            let data = {
                password: vals.password
            };
            this.$fnapi.patch(`/user/${this.focusedUser.id}`, data).then(r=>{
                this.notice = { notice: 'User password successfully updated', theme: 'news', icon: 'lock' }
                this.focusedUserMode = ''
                this.focusedUser = r.data;
                this.fetchUsers()
            })
            .catch((ex) => {  
                console.log('ex: ', ex)         
                let form = this.$refs[`updatePassForm${this.focusedUser.id}`];
                if(form) form = form[0];                
                if(ex && ex.response && ex.response.data && ex.response.data.detail)
                    form.localError = ex.response.data.detail[0].msg
                else
                    form.localError = 'There was an error updating the user'
            }); 
        }  
	},
	mounted(){
        document.title = 'Users-FedNow';
        this.fetchUsers()
        this.fetchSelf();
	},
    setup(){
        const users = ref([])
        const focusedUser = ref([])
        const focusedUserMode = ref('');
        const fi = ref(1)
        const notice = ref({})
        const page = ref(1)
        const pages = ref(1)
        const total = ref(0)
        const userSelf = ref([])
        const showNewUserForm = ref(false)

        return {
            fi,
            focusedUser,
            focusedUserMode,
            notice,
            page,
            pages,
            showNewUserForm,
            total,
            userSelf,
            users
        }

    }
}
</script>
