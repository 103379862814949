<template>
<div>
  <div :class="`${type == 'subheader' ? 'fnui-secondary' : 'fnui-content' }  flex flex-row items-center justify-between ${!noPadding && type != 'subheader' ? 'pt-3 pb-2 px-2' : ''}`">
    <slot />
      <!-- Other input types -->
      <label :for="id" :class="`${type != 'subheader' ? 'cursor-pointer' : '' } py-2 pl-1 text-left flex flex-row items-center`">
        <Icon v-if="icon && type != 'button'" :id="icon" :class="`${isFocused || type == 'checkbox' && value ? 'opacity-100' : 'opacity-40' } duration-150 h-8 w-8 mr-2 Xmb-3`" />
      </label>
      <!--Input / Note / Alert -->
      <div class="flex flex-col w-full">
        <!-- Button -->        
      <Button v-if="type == 'button'" :icon="icon" :label="label" @click="this.$emit('buttonClicked')" />
      <!--Subheader-->
      <div v-if="type == 'subheader'" class="w-full font-bold uppercase">{{label}}</div>
      <!--Text input (text, member, money) -->
        <div v-else-if="type == 'text' || type =='number' || type=='email' || type == 'member' || type == 'rtn' || type == 'money' || type == 'date' || type == 'ssn' || type == 'phone' || type == 'password'" 
            :class="`${!error && !note && !noPadding ? 'mb-5' : 'mb-0'} relative z-0 w-full flex justify-start`">
            <input 
            :type="inputType" :name="id"
            placeholder=" "
            :id="id"
            v-model="value"
            autocapitalize="none"
            autocorrect="off" 
            :maxlength="maxLengthInput"
            @input="onInput"
            @change="change"
            @keyup="keyup"
            @focus="focusField(true)"
            @blur="focusField(false)"
            class="fnui-input pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-b-2 appearance-none focus:outline-none focus:ring-0"
            />
            <label :for="id" class="absolute duration-300 top-3 z-1 pl-0 origin-0 opacity-80">{{label}}</label>
        </div>
        <!-- Checkbox -->
        <div v-else-if="type == 'checkbox'"
            @change="onChecked"
            class="flex flex-row items-center">
            <input type="checkbox" class="cursor-pointer h-8 w-8" v-model="value" :id="id" />
            <label :for="id" class="normal-label ml-2 flex-grow text-left  cursor-pointer">{{label}}</label>
        </div>
        <!--Text Area / Dropdown Select -->
        <div v-else-if="type == 'area' || isDropdownSelect"
            class="flex flex-col text-left w-full">
            <label :for="id" class="relative -top-0 pl-0 mb-1" style="font-size: 75%;">{{label}}</label>
            <textarea v-if="type == 'area'" :id="id" :name="id" v-model="value" 
            @focus="focusField(true)"
            @blur="focusField(false)"
            @input="onInput" class="w-full border border-b-2 rounded-sm p-1" />
            <!-- Select if options are grouped -->
            <select v-else-if="isDropdownSelect && hasGroups"
                @change="onInput"
                :id="id"   
                :tabindex="index"   
                v-model="value"                       
                :class="`${!selectOffsetCancel ? 'relative -top-2' : ''} fnui-input w-full pt-2 pb-2 border-b-2`">
                <optgroup v-for="group in optionGroups" :label="group" :key="group">
                    <option v-for="option in optionsInGroup(group)" :key="option.value" :value="option.value" style="bold">{{(option.label == undefined ? option.value : option.label)}}</option>
                </optgroup>
            </select>
            <!-- Select if options are not grouped -->
            <select v-else-if="isDropdownSelect && !hasGroups"
                @change="onInput"
                ref="refInput"
                :id="id"   
                :tabindex="index"   
                v-model="value"                       
                :class="`${!selectOffsetCancel ? 'relative -top-2' : ''} fnui-input w-full pt-2 pb-2 border-b-2`">
                    <option v-for="option in selectOptions" :key="option.value" :value="option.value" style="bold">{{(option.label == undefined ? option.value : option.label)}}</option>
            </select>
        </div>
        <!--Date input for mobiles-->
        <div v-if="type == 'date'" class="relative -top-5 w-full flex flex-row justify-start">
            <div class="block md:hidden flex flex-row justify-start border-l border-r border-b rounded-b">
                <select class="mn3-input py-2 px-2" v-model="dateSelectMonth" @change="updateDateValue()">
                    <option value="0">MM</option>
                    <option v-for="i in 12" :key="i">{{(i < 10 ? '0' : '') + i}}</option>
                </select>
                <select class="mn3-input py-2 px-2" v-model="dateSelectDay" @change="updateDateValue()">
                    <option value="0">DD</option>
                    <option v-for="i in 31" :key="i">{{(i < 10 ? '0' : '') + i}}</option>
                </select>
                <select class="mn3-input py-2 px-2" v-model="dateSelectYear" @change="updateDateValue()">
                    <option value="0">YYYY</option>
                    <option v-for="i in 110" :key="i">{{2025-i}}</option>
                </select>
            </div>
        </div>

        <!--Display note, error, code desc -->
        <span v-if="type == 'reasonCode' && value && appStore.codes.return_reasons[value]" @click="error = ''"
            :class="`relative -top-2 text-sm fnui-secondary mt-0 px-2 py-1 rounded-b-sm flex flex-row items-center font-bold`">{{appStore.codes.return_reasons[value].desc}}</span>
        <span v-if="type == 'infoCode' && value && appStore.codes.info_status[value] &&  appStore.codes.info_status[value].desc" @click="error = ''"
            :class="`relative -top-2 text-sm fnui-secondary mt-0 px-2 py-1 rounded-b-sm flex flex-row items-center font-bold`">{{appStore.codes.info_status[value].desc}}</span>
        <span v-if="type == 'incorrectInfo' && value && appStore.codes.incorrect_info[value]" @click="error = ''"
            :class="`relative -top-2 text-sm fnui-secondary mt-0 px-2 py-1 rounded-b-sm flex flex-row items-center font-bold`">{{appStore.codes.incorrect_info[value].desc}}</span>
        <span v-if="type == 'missingInfo' && value && appStore.codes.missing_info[value]" @click="error = ''"
            :class="`relative -top-2 text-sm fnui-secondary mt-0 px-2 py-1 rounded-b-sm flex flex-row items-center font-bold`">{{appStore.codes.missing_info[value].desc}}</span>
        <span v-if="error" @click="error = ''"
            :class="`${isDropdownSelect && !selectOffsetCancel ? 'relative -top-2' : ''} text-sm fnui-alert mt-0 px-1 rounded-b-sm flex flex-row items-center`">{{error}}</span>
        <span v-else-if="type == 'rtn' && value != ''"
            :class="`relative -top-5 text-sm fnui-secondary mt-0 px-1 rounded-b-sm flex flex-row items-center`">Name: {{rtnName}}</span>
        <span v-else-if="note" @click="this.$emit('noteClicked')"
            :class="`${isDropdownSelect && !selectOffsetCancel ? 'relative -top-2' : ''}
                ${noteEmit ? 'cursor-pointer opacity-90 hover:opacity-100 py-1' : ''} text-sm fnui-input-note mt-0 px-1 rounded-b-sm flex flex-row items-center`">{{note}}</span>
      </div>
      <!--Lookup buttons / Password Reveal-->
      <Button v-if="type == 'member'" icon="user" class="ml-2 mb-3" size="xs" />
      <Button v-if="type == 'rtn'" @click="showRtnSelect = !showRtnSelect" icon="building-office" class="ml-2 mb-3" size="xs" />
      <Button v-if="type == 'password'" @click="revealPassword = !revealPassword" 
        :icon="revealPassword ? 'eye-slash' : 'eye'" class="ml-2 mb-3" size="xs" />
  </div>
    <!-- RTN Select -->
    <div v-if="showRtnSelect" class="flex flex-col mx-5 mb-10">
        <Header title="RTN Lookup" :showClose="true" theme="secondary" 
            :hasQuickFilter="true" @close="showRtnSelect = false"  :isVertical="true"
            @update:quickFilter="updateRtnFilter" />
        <div class="w-full flex flex-col max-h-64 overflow-auto text-left leading-4">
            <div v-for="p in appStore.participants" :key="p.rtn" @click="value = p.rtn;  this.$emit('valueUpdated', {id: id, value: value});showRtnSelect = false"
                :class="`${rtnFilter.length > 0 && p.name.toLowerCase().indexOf(rtnFilter.toLowerCase()) == -1 ? 'hidden' : ''} flex flex-row justify-between px-1 py-2 cursor-pointer opacity-60 hover:opacity-100 duration-150`"><span>{{p.name}}</span><span>{{p.rtn}}</span></div>
        </div>
    </div>
</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAppStore } from '@/stores/app'

import { ref } from "vue";

import Button from "@/components/fnui/Button.vue";
import Header from "@/components/fnui/Header.vue";
import Icon from "@/components/fnui/Icon.vue";

export default {
    components: {
        Button,
        Header,
        Icon
    },
    props:{
        allowNegative: Boolean,
        icon: String,
        id: String,
        initialValue: String,
        isRequired: { type: Boolean, default: true },
        label: String,
        minLength: Number,
        maxLength: Number,
        noPadding: Boolean,
        note: String,
        noteEmit: String,
        options: Object,
        placeholder: String,
        selectOffsetCancel: Boolean, //Don't adjust select Y for lining up with other fields in form
        type: { type: String, default: 'text' }
    },
    computed: {
		...mapStores(useAppStore),
        hasGroups(){
            let ret = false;
            if(this.$props.options == undefined) return false;            
            for(var ii = 0; ii < this.$props.options.length; ii++){
                if(this.$props.options[ii].group != null)
                    ret = true;
            }
            return ret;
        },
        inputType(){
            let type = this.$props.type;
            if(type == 'money' || type == 'rtn' || type == 'number') return 'number';
            if(type == 'email') return 'text';
            if(type == 'date') return type;
            if(type == 'password' && !this.revealPassword) return 'password';

            return 'text';
        },
        isDropdownSelect(){
            return this.type == 'select' || this.type == 'reasonCode' || this.type == 'infoCode' || this.type == 'missingInfo' || this.type == 'incorrectInfo' || this.type == 'transferStatus';
        },
        /* The maxlength used on the input field */
        maxLengthInput(){
            if(this.$props.maxLength) return this.$props.maxLength;
            if(this.$props.type == 'ssn') return 11;
            if(this.$props.type == 'phone') return 12;

            return '';
        },
        optionGroups(){
            let groups = [];
            for(var ii = 0; ii < this.$props.options.length; ii++){
                if(!groups.includes(this.$props.options[ii].group))
                    groups.push(this.$props.options[ii].group);
            }            
            return groups;
        },
        incorrectInfoOptions(){
            let options = [];         
            for(var ii = 0; ii < this.appStore.codes.allowed_incorrect_info.length; ii++){
                let code = this.appStore.codes.allowed_incorrect_info[ii]
                let opt = this.appStore.codes.incorrect_info[code]
                options.push({ value: opt.code, label:  opt.code + ': ' + opt.name})
            }
            return options;
        },
        infoOptions(){
            let options = [];            
            for(var key in this.appStore.codes.info_status){
                let opt = this.appStore.codes.info_status[key]
                options.push({label: opt.code + ': ' + opt.name, value: opt.code})
            }         
            return options;
        },
        missingInfoOptions(){
            let options = [];         
            for(var ii = 0; ii < this.appStore.codes.allowed_missing_info.length; ii++){
                let code = this.appStore.codes.allowed_missing_info[ii]
                let opt = this.appStore.codes.missing_info[code]
                options.push({ value: opt.code, label:  opt.code + ': ' + opt.name})
            }
            return options;
        },
        returnOptions(){
            let options = [];            
            for(var key in this.appStore.codes.return_reasons){
                let opt = this.appStore.codes.return_reasons[key]
                options.push({label: opt.code + ': ' + opt.name, value: opt.code})
            }         
            return options;
        },
        transferStatusOptions(){
            let options = [];         
            for(var key in this.appStore.codes.transfer_status){
                let opt = this.appStore.codes.transfer_status[key]
                options.push({ value: opt.code, label:  opt.code + ': ' + opt.name})
            }
            return options;
        },
        rtnName(){
            let all = this.appStore.participants
            for(var ii = 0; ii < all.length; ii++){
                if(all[ii].rtn == this.value) return all[ii].name
            }
            return '';
        },
        // Options for dropdown select to use
        selectOptions(){
            if(this.$props.options) {
                return this.$props.options
            }
            else if(this.$props.type == 'infoCode'){
                return this.infoOptions
            }
            else if(this.$props.type == 'reasonCode'){
                return this.returnOptions
            }
            else if(this.$props.type == 'missingInfo'){
                return this.missingInfoOptions
            }
            else if(this.$props.type == 'incorrectInfo'){
                return this.incorrectInfoOptions
            }
            else if(this.$props.type == 'transferStatus'){
                return this.transferStatusOptions
            }
            
            return [];
        }
    },  
    methods: {
              
        change(evt){
            if(this.$props.type == "date"){
                this.updateDateSelectValues();
                this.$emit('blur');
            }
        },    
        // Fetch current system date and save in store 
        async fetchSettlementDate(){
            try{
                let resp = await this.$fnapi.get(`/status`)
                this.value = resp.data.settlement_date;
                this.$emit('valueUpdated', {id: this.$props.id, value: this.value}); // Emit event to update the value in the parent
            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
         /* Returns true if val has an '@' before a '.' */
         isValidEmail(val){
            if(val == undefined || val == null)
                return false
            let indexA = val.indexOf('@')
            let indexB = val.lastIndexOf('.')
            if(indexA === -1 || indexB === -1 || indexB < indexA)
                return false
            return true;
        }, 
        onChecked($event){
            this.$emit('valueUpdated', {id: this.$props.id, value: $event.target.checked}); // Emit event to update the value in the parent
        },
        onInput($event) {
          this.$emit('valueUpdated', {id: this.$props.id, value: $event.target.value}); // Emit event to update the value in the parent
        },
        optionsInGroup(group){
            let options = [];
            for(var key in this.$props.options){
                let opt = this.$props.options[key];
                if(opt.group == group){
                    options.push({ label: opt.label, value: opt.value});
                }
            }
            return options;
        },
        clearErrors(){
            this.error = '';
        },    
        focusField(isFocused){
            this.isFocused = isFocused;
        },
        keydown(evt){
            this.emit('keydown')
          
        },        
        keyup(evt){            
            if(evt.key == 'Enter'){
                this.emit('enter') 
                evt.preventDefault();
            }     
            
            //If a character was just deleted, don't run mask
            if(evt.code != "Backspace" && evt.key != "Backspace" && evt.keyCode != 8){
                switch(this.type){
                    case 'date':
                        this.mask(evt.target, this.mdate);
                        break;

                    case 'ssn':
                        this.mask(evt.target, this.mssn);
                        break;

                    case 'phone':
                        this.mask(evt.target, this.mphone);
                        break;
                }
            }    
        },
        mask(o, f) {
            setTimeout(function () {
                var v = f(o.value);
                if (v != o.value) {
                    o.value = v;
                }
            }, 1);
        },/* Date Formatter */
        mdate(v) {
            var r = v.replace(/\D/g,""); // Remove all non-digit characters
            if (r.length > 8) {
                // More than 8 digits. Format as MM/DD/YYYY and prevent further input
                r = r.replace(/^(\d{2})(\d{2})(\d{4}).*/,"$1/$2/$3");
            } else if (r.length > 4) {
                // 5 to 8 digits. Format as MM/DD/YYYY
                r = r.replace(/^(\d{2})(\d{2})(\d{0,4})/,"$1/$2/$3");
            } else if (r.length > 2) {
                // 3 to 4 digits. Format as MM/DD
                r = r.replace(/^(\d{2})(\d{0,2})/,"$1/$2");
            } else {
                // 0 to 2 digits. Leave as is
                r = r.replace(/^(\d*)/, "$1");
            }
            return r;
        },
        /* Phone# Formatter */
        mphone(v) {
            var r = v.replace(/\D/g,"");
            r = r.replace(/^0/,"");
            if (r.length > 10) {
                // 11+ digits. Format as 5+4.
                //r = r.replace(/^(\d\d\d)(\d{5})(\d{4}).*/,"($1) $2-$3");
                r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/,"$1-$2-$3");
                return r;
            }
            else if (r.length > 5) {
                // 6..10 digits. Format as 4+4
                r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/,"$1-$2-$3");
            }
            else if (r.length > 2) {
                // 3..5 digits. Add (0XX..)
                r = r.replace(/^(\d\d\d)(\d{0,3})/,"$1-$2");
            }
            else {
                // 0..2 digits. Just add (0XX
                r = r.replace(/^(\d*)/, "$1");
            }
            return r;
        },
        /* SSN Formatter */
        mssn(v) {
            var r = v.replace(/\D/g,"");
            if (r.length > 9) {
                r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
                return r;
            }
            else if (r.length > 4) {
                r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
            }
            else if (r.length > 2) {
                r = r.replace(/^(\d\d\d)(\d{0,3})/,"$1-$2");
            }
            else {
                r = r.replace(/^(\d*)/, "$1");
            }
            return r;
        },
        onError(a){
            this.error = a;
        },
        focus(){
            this.refInput.focus();
        },        
        // Update the input's value to the dropdown selected date
        updateDateValue(){
            //Set unset day/month to 01 so display updates 
            if(this.dateSelectMonth == '0') this.dateSelectMonth = '1';
            if(this.dateSelectDay == '0') this.dateSelectDay = '1';
            if(this.dateSelectYear == '0') this.dateSelectYear = this.$props.defaultYear;
            //Update values
            this.value = this.dateSelectYear + '-' 
                + (this.dateSelectMonth.length == 1 ? '0' : '') + this.dateSelectMonth + '-' 
                + (this.dateSelectDay.length == 1 ? '0' : '')  + this.dateSelectDay;
        },
        // Update date dropdowns to match input value
        updateDateSelectValues(){
            let split = this.value.split('-');
            if(split.length == 3){
                this.dateSelectYear = split[0];
                this.dateSelectMonth = split[1];
                this.dateSelectDay = split[2];
            }
        },
        updateRtnFilter(val){
            this.rtnFilter = val
        },
        validationCheck(){

            // Get length of input value
            let len = this.value ? this.value.length : 0;

            if(!this.$props.isRequired || this.$props.type == 'subheader') return { success: true }

            // If required, make sure not empty
            if(this.$props.isRequired && len == 0 && this.$props.type != 'checkbox'){
                return { success: false, error: `${this.$props.label} is required` }
            }

            // If a number that doesn't allow negative
            if((this.$props.type == 'number' || this.$props.type == 'money') && !this.$props.allowNegative){
                let numVal = parseFloat(this.value)
                if(numVal < 0){
                    return { success: false, error: `${this.$props.label} cannot be negative` }
                }
            }

            // Make sure of a valid length
            if((this.$props.minLength && len < this.$props.minLength) 
                || (this.$props.maxLength && len > this.$props.maxLength)){
                let error = '';

                if(this.$props.minLength && this.$props.maxLength)
                    error = `${this.$props.label} must be between ${this.$props.minLength} and ${this.$props.maxLength} characters`;
                else if(this.$props.minLength)
                    error = `${this.$props.label} must be at least ${this.$props.minLength} characters`;
                else if(this.$props.maxLength)
                    error = `${this.$props.label} must be under ${this.$props.maxLength} characters`;

                return { success: false, error: error };
            }

            // Check format specific reqs (SSN, phone length, valid email format)
            if(this.$props.type == 'ssn' && len != 11){
                return { success: false, error: `${this.$props.label} must be a 9 digits` }
            }            
            else if(this.$props.type == 'phone' && len != 12){
                return { success: false, error: `${this.$props.label} must be a 10 digits` }
            }
            else if(this.$props.type == 'email' && !this.isValidEmail(this.value)){
                return { success: false, error: `${this.$props.label} must be a valid address` }
            }

            return { success: true }
        }
    },
    created(){
        //If date, check for setting to settlement date by default
        if(this.$props.type == 'date' && !this.$props.initialValue){
            this.fetchSettlementDate();
        }
    },
    setup(props, { emit }){
        const error = ref("");      //error: Validation error on the field
        const refInput = ref(null)
        const isFocused = ref(false)
        const value = ref(props.initialValue);
        const revealPassword = ref(false)

        const dateSelectDay = ref('0');
        const dateSelectMonth = ref('0');
        const dateSelectYear = ref('0');
        const rtnFilter = ref('')
        const showRtnSelect = ref(false)
        return {
            dateSelectDay,
            dateSelectMonth,
            dateSelectYear,
           emit,
           error,
           isFocused,
           refInput,
           revealPassword,
           rtnFilter,
           showRtnSelect,
           value
        }
    }
}
</script>
