<!-- Button -->
<template>
    <div class="w-full flex flex-row justify-between p-0 fnui-secondary items-center">
        <Button @click="hasPrevious ? this.$emit('previous') : ''" :class="`${hasPrevious ? 'opacity-100' : 'opacity-40'}`" label="Previous" size="sm" theme="secondary" rounded="none" icon="arrow-left" />
        <div class="flex flex-col items-center">
            <div class="leading-5">Viewing Page {{page}} of {{pages}}</div>
            <div class="text-xs leading-3 font-bold">{{total}} Total Records</div>
        </div>
        <Button @click="hasNext ? this.$emit('next') : ''" :class="`${hasNext ? 'opacity-100' : 'opacity-40'}`" label="Next" size="sm" theme="secondary" rounded="none" icon="arrow-right" :iconAfterLabel="true" />
    </div>
</template>

<script>
import Button from "@/components/fnui/Button.vue";
import Icon from "@/components/fnui/Icon.vue";

import { ref } from "vue";

export default {
  components: {
    Button,
    Icon
  },
  props: {
    page: Number,
    pages: Number,
    total: Number
  },
  computed:{
    hasNext(){
        return this.$props.page < this.$props.pages;
    },
    hasPrevious(){
        return this.$props.page > 1;
    }
  },
  methods: {
  },
  setup(props, { emit }) {
    
    return { 
    };
  }
};
</script>
