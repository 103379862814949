<template>
  <div class="about">
    <h1>This is the home page</h1>

		<div v-if="userStore.isAmiAdmin">
			<p>Hello AMI admin! Here are all the FI's</p>
			<p v-for="fi in fis">
				{{fi.name}}
			</p>
		</div>
		<div v-else-if="fi">
			<p>Hello, you are viewing {{fi.name}}.</p>
			<p>You have access to the following participants:</p>
			<p v-for="p in userStore.participantIds">{{p}}</p>
		</div>
		<div v-else>
			Loading..
		</div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

export default {
	data(){
		return {
			fis: [],
			fi: null,
		};
  },
	computed: {
		...mapStores(useUserStore),
  },
	methods: {
		fetchFis(){
				if(this.userStore.isAmiAdmin){
					return this.$fnapi.get("fi/").then((r)=>{
							this.fis = r.data.items;
					});
			  }else{
					return this.$fnapi.get("fi/me").then((r)=>{
							this.fi = r.data;
					});
			  }
		}
	},
	created(){
        document.title = 'FedNow';
			this.fetchFis();
	}
}
</script>
