<!-- SideNav: The pop-in side navigation menu -->
<template>
    <!-- Left Nav -->
    <div class="z-10 flex flex-col fnui-header flex-shrink-0 self-start border-r-4 border-b-4 rounded-br-lg overflow-hidden">
        <div class="w-full text-center text-2xl font-bold uppercase p-2"><img src="@\assets\fednow-tr.png" /></div>
        <div v-if="userStore.isLoggedIn" class="fnui-secondary flex flex-row justify-center pl-3 pr-1 py-2">
            <Button @click="$fnapi.logout" size="xs" label="logout" theme="cancel" icon="logout" class="w-2/3" />
        </div>
        <Button v-for="b in menuOptions" @click="goTo(b.to)" :theme="this.$route.path == b.to ? 'button-focused' : 'button'" :icon="b.icon" :label="b.label" :key="b.label" justify="justify-start" rounded="none" class="text-left"/>
    </div>
</template>
<script>
    import { mapStores } from 'pinia'
    import { useAppStore } from '@/stores/app'
    import { useUserStore } from '@/stores/user'
	
	import Button from '@/components/fnui/Button.vue'
	import Icon from '@/components/fnui/Icon.vue'
	
	export default {
	  components: {
        Button,
        Icon
	  },
	  props: {
	  },
      computed: {
		...mapStores(useAppStore, useUserStore),
        menuOptions(){
            let options = [];
            
                if(this.userStore.isLoggedIn){
                    options.push({ label: 'Home', icon: 'home', to: '/' })
                    
                options.push({ label: 'Settings', icon: 'wrench-screwdriver', to: '/user' })

                if(this.userStore.permissions.users)
                    options.push({ label: 'Users', icon: 'users', to: '/users'})

                if(this.userStore.permissions.reports)
                    options.push({ label: 'Reports', icon: 'document', to: '/reports' })
                    
                if(this.userStore.permissions.cct_send)
                    options.push({ label: 'Send Money', icon: 'dollar', to: '/send' })    

                if(this.userStore.permissions.participants)     
                    options.push({ label: 'Participants', icon: 'users', to: '/participants' })  
                    
                if(this.userStore.permissions.cct_investigate)  
                    options.push({ label: 'Cases', icon: 'briefcase', to: '/cases' }) 
                
                if(this.userStore.permissions.cct_read)
                    options.push({ label: 'Customer Credit Transfers', icon: 'receipt-empty', to: '/cct' })

            }

            return options;
        }
      },
	  created(){        
	  },
      methods:{     
        goTo(to){
            if(this.$route.path == to){
                //If going to case, set store value so cases loads last search
                if(to == '/cases')
                    localStorage['reloadCases'] = 1;

                this.$router.go()
            }else
            this.$router.push(to)
        }
      },
	  setup(props, { emit }){
	    return {
	    }
	  }
	}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 2px;
  opacity: 0.5;
}
</style>