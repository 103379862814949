<template>
  <div class="flex flex-row w-full max-w-screen-lg">
    <Form title="Search Reports" submitText="Update" class="flex-shrink-0 w-96" @submit="updateReports"
        @updateMode="updateMode" ref="searchForm"
            :fields="searchFields" />
    <Table :title="`Report${ reports.code ? ': ' +reports.code : '' }`" icon="document" class="ml-5 w-full">
        <!-- IATR/AATR Report -->
        <div v-if="reports.code == 'IATR' || reports.code == 'AATR' || reports.code == 'AADR'" class="flex flex-col p-2 gap-y-2">
            <div class="w-full flex flex-row justify-around">
                <Table theme="secondary" title="Credits" class="mx-auto">
                    <Display label="Total" :value="reports.summary.credits.total" />
                    <Display label="Sum" :value="`$${parseFloat(reports.summary.credits.sum).toFixed(2)}`" />
                </Table>
                <Table theme="secondary" title="Debits" class="mx-auto">
                    <Display label="Total" :value="reports.summary.debits.total" />
                    <Display label="Sum" :value="`$${parseFloat(reports.summary.debits.sum).toFixed(2)}`" />
                </Table>
            </div>
            <Table theme="secondary" title="Codes" class="mx-auto w-5/6">
                <div class="flex flex-row justify-around fnui-content">
                    <Display v-for="(code, key) in reports.summary.codes" :key="key" 
                        :label="key" :value="code.total" />
                </div>
            </Table>
        </div>
        <!--Summaries-->
        <div v-if="reports.summaries" class="flex flex-col w-full px-5">
            <Table :title="s.title" theme="secondary" v-for="s in reports.summaries" :key="s.title" class="mt-5">
                <div class="w-full flex flex-row justify-around p-2">
                    <Table theme="secondary" title="Credits" class="mx-auto">
                        <Display label="Total" :value="s.credits.total" />
                        <Display label="Sum" :value="`$${parseFloat(s.credits.sum).toFixed(2)}`" />
                    </Table>
                    <Table theme="secondary" title="Debits" class="mx-auto">
                        <Display label="Total" :value="s.debits.total" />
                        <Display label="Sum" :value="`$${parseFloat(s.debits.sum).toFixed(2)}`" />
                    </Table>
                </div>                
            </Table>
        </div>
        <!-- Report Entires -->
        <div v-if="reports.code == 'AADR' || reports.code == 'AMI Posting Report'" class="w-full flex flex-col">
            <div v-for="report in reports.entries" :key="report.id" class="flex flex-row flex-wrap p-2 border-t">
                <div v-for="field in entryFields(report)" :key="field.key" :class="`${field.width ? field.width : ''} flex flex-row items-center text-sm`">
                    <Icon :id="field.icon" class="h-6 w-6 mr-1" />
                    <span class="font-bold mr-1">{{field.label}}:</span> {{field.value}}
                </div>                
            </div>
            <Button v-if="reports && !reports.last" label="Load More Records" />
        </div>
        <div v-if="!reports.code" class="p-5">
            <Notice notice="Use the form on the left to search reports" />
        </div>
    </Table>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'

import { ref } from 'vue'

import Display from '@/components/fnui/Display.vue'
import Form from '@/components/fnui/Form.vue'
import Icon from '@/components/fnui/Icon.vue'
import Notice from '@/components/fnui/Notice.vue'
import Pages from '@/components/fnui/Pages.vue'
import Table from '@/components/fnui/Table.vue'

export default {
    name: 'Reports',
    components: {
        Display,
        Form,
        Icon,
        Notice,
        Pages,
        Table
    },
	computed: {
		...mapStores(useUserStore, useAppStore),
        initialRtn(){
            if(this.userStore.participantIds && this.userStore.participantIds[0]){
                return this.userStore.participantIds[0]
            }
            return '';
        },
        searchFields(){
            let fields = [];
            fields.push({ id: 'mode', type: 'select', label: 'Report', icon: 'queue-list', changeEmit: 'updateMode', initialValue: this.mode,  
                options: [ 
                    { value: 'aadr', label: 'FN:AADR - Account Activity Details Report' }, 
                    { value: 'aatr', label: 'FN:AATR - Account Activity Totals Report' }, 
                    { value: 'iatr', label: 'FN:IATR - Intraday Account Activity Totals Report' },
                    { value: 'amis', label: 'AMI: Posts by Settlement' },
                    { value: 'amip', label: 'AMI: Posts by Posting Date' } ]})
            
            fields.push({ id: 'rtn', type: 'text',  initialValue:  this.initialRtn, icon: 'hashtag', label: 'RTN' })

            if(this.mode != 'iatr')
                fields.push({ id: 'date', type: 'date', icon: 'calendar', label: this.dateLabel })

            return fields;
        },
        dateLabel(){
            if(this.mode == 'amip')
                return 'Core Posting Date';

            return 'Settlement Date';
        }
  },
	methods: {
        displayDate(raw){
            let utcDate = new Date(raw)
            let options = {
                timeZoneName: 'short',
                year: 'numeric',
                month: 'numeric', 
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }
            let localDate = new Intl.DateTimeFormat(navigator.language, options).format(utcDate);
            return localDate;
        },
        entryFields(entry){
            let fields = [{ label: 'ID', value: entry['id'], icon: 'hashtag', width: 'w-full' }];

            if(entry.amount)
                fields.push({ label: 'Amount', value: '$' + parseFloat(entry['amount']).toFixed(2), icon: 'dollar', width: 'w-1/2' })
            
            if(entry.account)
                fields.push({ label: 'Account', value: entry['account'], icon: 'user', width: 'w-1/2' })

            if(entry.sent)
                fields.push({ label: 'Sent', value: this.displayDate(entry['sent']), icon: 'clock', width: 'w-1/2' })            
            else if(entry.posted)
                fields.push({ label: 'Posted', value: this.displayDate(entry['posted']), icon: 'clock', width: 'w-1/2' })
            
            if(entry.settlement_date)
                fields.push({ label: 'Settlement Date', value: entry['settlement_date'], icon: 'clock', width: 'w-1/2' })

            if(entry.status)
                fields.push({ label: 'Status', value: entry['status'], icon: 'book-open', width: 'w-1/2' })
            
            if(entry.mdi)
                fields.push({ label: 'MDI', value: entry['mdi'], icon: 'hashtag', width: 'w-1/2' })
            
            if(entry.cdi)
                fields.push({ label: 'CDI', value: entry['cdi'], icon: 'hashtag', width: 'w-1/2' })
            
            if(entry.code)
                fields.push({ label: 'Code', value: entry['code'], icon: 'hashtag', width: 'w-1/2' })


            return fields;
        },
        async fetchReports(){
            try{

                let url = ''
                if(this.mode == 'amis' || this.mode == 'amip'){
                    url = `/report/posts-by-${this.mode == 'amis' ? 'settlement' : 'posted'}`;
                }else{
                    url = `/report/${this.mode}`;
                }

                let resp = await this.$fnapi.get(`${url}?rtn=${this.rtn}${this.mode != 'iatr' ? '&date=' + this.date : ''}`)       
                this.reports = resp.data
            }catch(resp){
                if(!resp.response && resp.message)
                    this.$refs.searchForm.localError = resp.message;
                else{
                    let error = '';

                    if(resp.response.data.detail[0].msg)
                        error = resp.response.data.detail[0].msg;
                    else if(resp.response.data.detail)
                        error = resp.response.data.detail;

                    //Check for error code
                    if(resp.response.data.detail[0].code && this.appStore.codes.transfer_reasons[resp.response.data.detail[0].code])
                        error += ': ' + this.appStore.codes.transfer_reasons[resp.response.data.detail[0].code].desc;
                    
                    if(error == '')
                        error = 'There was an error fetching the report data.'

                    this.$refs.searchForm.localError = error
                }
            }
        },
        updateReports(vals){
            this.reports = {};
            this.rtn = vals.rtn
            this.date = vals.date
            this.fetchReports();
        },
        updateMode(data){
            this.mode = data
        }
	},
	created(){        
        document.title = 'Reports-FedNow';
	},
    setup(){
        const mode = ref('aadr')
        const date = ref('')
        const rtn = ref('')
        const reports = ref([])
        return { date, mode, rtn, reports }
    }
}
</script>
