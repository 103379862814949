<template>
    <div class="flex flex-row w-full max-w-screen-md gap-x-5">
        <Table icon="command-line" title="Status" class="w-96">
            <Display label="Fed Now Status" :value="status.status" />
            <Display label="Settlement Date" :value="status.settlement_date" />
        </Table>
        <Table v-if="userStore.isAmiAdmin" icon="lock-open" title="Admin Access" desc="Available FIs">
			<div v-for="fi in fis" :key="fi.name" class="fnui-content w-full p-2 font-bold text-lg border-b">
				{{fi.name}}
			</div>
		</Table>
		<Table v-else-if="fi" :title="fi.name" icon="building-office" desc="You have access to the following participants">
			<div v-for="p in userStore.participantIds" :key="p" class="fnui-content w-full p-2 font-bold text-lg border-b">{{p}}</div>
		</Table>
		<div v-else>
			Loading..
		</div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

import { ref } from 'vue'

import Display from '@/components/fnui/Display.vue'
import Icon from '@/components/fnui/Icon.vue'
import Pages from '@/components/fnui/Pages.vue'
import Table from '@/components/fnui/Table.vue'

export default {	
    data(){
		return {
			fis: [],
			fi: null,
		};
    },
    name: 'Status',
    components: {
        Display,
        Icon,
        Pages,
        Table
    },
	computed: {
		...mapStores(useUserStore),
  },
	methods: {
		fetchFis(){
				if(this.userStore.isAmiAdmin){
					return this.$fnapi.get("fi/").then((r)=>{
							this.fis = r.data.items;
					});
			  }else{
					return this.$fnapi.get("fi/me").then((r)=>{
							this.fi = r.data;
					});
			  }
		},
        async fetchStatus(){
            try{
                let resp = await this.$fnapi.get(`/status`)       
                console.log('fetchStatus.resp: ', resp);
                this.status = resp.data
            }catch(resp){
                console.log('fetchStatus catch: ', resp);
            }
        },
	},
	mounted(){
        document.title = 'Status-FedNow';
        this.fetchStatus()
        this.fetchFis();
	},
    setup(){
        const status = ref('')
        return {  status };
    }
}
</script>
