<template>
    <!-- Focused Case -->
    <div v-if="focusedCase.id" class="max-w-screen-xl">
        <Table icon="briefcase" title="Focused Case" :showClose="false" :buttons="[{ label: 'Close', icon: 'x', theme: 'button', emit: 'close'}]" @close="focusedCase = {};focusedMessage = {};">
        <!-- Breadcrumbs -->
            <div class="w-full fnui-secondary p-1 text-sm flex justify-start gap-x-1 capitalize">
                <span @click="focusedCase = {}; focusedMessage = {};" class="cursor-pointer flex flex-row items-center">
                    <Icon id="magnifying-glass" class="h-4 w-4 mr-0.5" />
                    <span>{{tableTitle}}</span>
                </span>

                <span>/</span>
                <span @click="focusedMessage = {}; mode = '';" :class="`${!focusedMessage.id ? 'font-bold' : ''} cursor-pointer flex flex-row items-center`">
                    <Icon id="briefcase" :class="`h-4 w-4 mr-0.5 ${focusedMessage.id ? 'opacity-60' : ''}`" />
                    <span>Case Details</span>
                </span>

                <span v-if="focusedMessage.id">/</span>
                <span v-if="focusedMessage.id" @click="mode = ''" :class="`${mode != 'respond' ? 'font-bold' : 'cursor-pointer'} flex flex-row items-center`">
                    <Icon id="document-text" :class="`h-4 w-4 mr-0.5 ${mode == 'respond' ? 'opacity-60' : ''}`" />
                    <span>{{focusedMessage.description}}</span>
                </span>

                <span v-if="mode == 'respond'">/</span>
                <span v-if="mode == 'respond'" class="font-bold flex flex-row items-center">
                    <Icon id="chat-bubble-left" :class="`h-4 w-4 mr-0.5`" />
                    <span>Send Response</span>
                </span>
            </div>
            <!-- Focused Case info-->
            <div class="py-4 border-b flex flex-row flex-wrap ">
            <div v-for="field in 
                [ { title: 'Created At', icon: 'clock', value: displayDate(focusedCase['created_at']) },
                  { title: 'Last Updated', icon: 'clock', value: displayDate(focusedCase['updated_at']) },
                  { title: 'RTN', icon: 'building-office', label: 'RTN', value: focusedCase['participant_id'] } ,
                  { title: 'Status', icon: 'user', label: 'Status', value: focusedCase['status'] },
                  { title: 'Resolved', icon: 'user', label: 'Resolved', value: focusedCase['flags']['RESOLVED'] } ]" 
            :key="field.title" :title="field.title" class="flex items-center w-1/3 leading-4 px-2">
                <Icon v-if="field.icon" :id="field.icon" class="h-4 w-4 mr-1" />{{field.label ? field.label + ': ' : ''}}{{field.value}}
                </div>
            </div>
            <div class="w-full flex flex-row justify-start">
                <!--Messages List-->
                <div class="w-96 flex flex-col border-r">
                    <Header theme="secondary" title="Case Message History" icon="envelope" :hasRoundedTop="false" />
                    <div class="w-full flex flex-col py-2 rounded-sm overflow-y-auto max-h-96">
                        <div :title="JSON.stringify(m)"
                            v-for="m in focusedCase.messages" 
                            :class="`${focusedMessage.id == m.id ? 'fnui-content-focused' : focusedMessage.id ? 'opacity-60' : ''} w-full flex flex-col justify-start p-1`" :key="m.description">

                            <div class="w-full flex flex-row">
                                <div class="w-20 mr-1 flex flex-row gap-x-0.5 justify-start">
                                    <Button class="w-full" @click="focusMessage(m)" rounded="sm" size="xs" icon="document-text" title="View Message Details" />
                                </div>
                                <div class="flex flex-col text-left items-start">
                                    <div class="font-bold leading-4">{{m.description}}</div>
                                    <div>{{detailHeader(m, false)}}</div>
                                    <div class="font-mono text-sm">{{displayDate(m.created_at)}}</div>
                                </div>     
                            </div>           
                        </div>
                    </div>
                </div>
                <!--Focused Area-->
            <!-- Focused Message -->
            <Table v-if="focusedMessage.id && mode != 'respond' && mode != 'responseSent'" theme="secondary" class="w-full" icon="document-text"
                @respond="respond(focusedMessage)" :isRounded="false"
                :buttons="canRespond(focusedMessage) ? [{label: 'Respond', emit: 'respond', icon: 'chat-bubble-left'}] : []"
                :title="focusedMessage.description" :showClose="true" closeTitle="Close focused message" @close="focusedMessage = {};mode = ''">
                <div class="w-full flex flex-row flex-wrap text-left">
                    <!-- Case Info area-->
                    <div :class="`${focusedMessage.detail.missing_info || focusedMessage.detail.incorrect_info ? 'mt-2' : 'my-2'} border w-full text-left flex flex-col fnui-notice px-2`">
                        <div v-if="!focusedMessage.detail.missing_info && !focusedMessage.detail.incorrect_info" class="w-full flex flex-row gap-x-2 justify-start text-lg items-center">
                            <Icon id="info" class="h-8 w-8 flex-shrink-0" />
                            <div class="leading-5">
                                <span class="font-bold mr-1">{{detailHeader(focusedMessage, true)}}</span>
                                {{detailDescription(focusedMessage)}}
                            </div>
                        </div>
                        <div v-if="focusedMessage.detail.reason_text" class="italic text-sm mx-2 mt-1">Additional Info: {{focusedMessage.detail.reason_text}}</div>
                        <div v-if="focusedMessage.detail.status" class="mt-1 text-sm font-bold bt-1">Case Status: {{focusedMessage.detail.status}}</div>
                        <div v-if="focusedMessage.detail.resolution_related_info" class="mt-1 text-sm font-bold bt-1">Resolution Info: {{focusedMessage.detail.resolution_related_info.tran_id}}</div>
                    </div>
                    <!--Missing/Incorrect Info-->
                    <div v-if="focusedMessage.detail.missing_info" class="w-full fnui-notice flex flex-col mb-2" >
                        <Notice notice="Missing Information" icon="info" theme="notice" />
                        <div v-for="info in focusedMessage.detail.missing_info" class="fnui-notice w-full flex flex-col items-start flex-grow-0 flex-shrink-0 p-2 border-b leading-5" :key="info.code">
                            <div><span class="font-bold">({{info.code}}) {{appStore.codes.missing_info[info.code].name}}</span>: {{appStore.codes.missing_info[info.code].desc}}</div>
                            <div v-if="info.message" class="italic text-sm">Additional Info: {{info.message}}</div>
                        </div>
                    </div>
                    <div v-if="focusedMessage.detail.incorrect_info" class="w-full fnui-notice flex flex-col mb-2" >
                        <Notice notice="Incorrect Information" icon="info" theme="notice" />
                        <div v-for="info in focusedMessage.detail.incorrect_info" class="fnui-notice w-full flex flex-col items-start flex-grow-0 flex-shrink-0 p-2 border-b leading-5" :key="info.code">
                            <div><span class="font-bold">({{info.code}}) {{appStore.codes.incorrect_info[info.code].name}}</span>: {{appStore.codes.incorrect_info[info.code].desc}}</div>
                            <div v-if="info.message" class="italic text-sm">Additional Info: {{info.message}}</div>
                        </div>
                    </div>
                    <!-- Focused message values -->
                    <div v-for="field in [
                        { title: 'ID', value: focusedMessage.id },
                        { title: 'Created At', value: displayDate(focusedMessage.created_at) },
                        { title: 'Inserted At', value: displayDate(focusedMessage.inserted_at) },
                        { title: 'Participant ID', value: focusedMessage.participant_id },
                        { title: 'Transfer ID', value: focusedMessage.transfer_id },
                        { title: 'Case ID', value: focusedMessage.case_id },
                        { title: 'Description', value: focusedMessage.description },
                        { title: 'MDI', value: focusedMessage.mdi }
                    ]" :key="field.title" class="border w-1/2 p-1 text-left">
                        <span class="font-bold">{{field.title}}:</span> {{field.value}}
                    </div>
                    <div v-for="(value, key) in focusedMessage.flags" :key="key"
                        class="border w-1/4 text-left p-1">
                        <span class="font-bold">{{key}}:</span> {{value}}                        
                    </div>
                </div>
            </Table>

            <!-- Response form -->
            <Form v-if="mode == 'respond'" class="w-2/3 mx-auto my-2" theme="secondary" 
                @submit="respondSubmit" ref="respondForm"
                :fields="responseFields" @statusUpdated="responseStatusUpdated"
                title="Respond To Message" :showClose="true" @close="mode = ''" :showCancel="true" @cancel="mode = ''" submitText="Respond to Case"></Form>
            <!-- Response sent -->
            <Notice v-else-if="mode == 'responseSent'" class="mx-2 my-4 w-full" notice="Your response has been sent" :showClose="true" @close="mode = ''" />
            </div>
        </Table>
    </div>
    <!-- Lookup / View Cases-->
    <div v-else class="flex flex-row max-w-screen-xl">
        <Form title="Case Lookup"
            ref="lookup"
            class="w-80 flex-shrink-0"
            icon="eye"
            submitText="Lookup"
            @submit="lookup"
            :fields="[
                { id: 'participant', initialValue: initialRtn, label: 'RTN', type: 'text' },
                { id: 'status', label: 'Status', type: 'select', initialValue: 'open', options: [ { label: 'Open', value: 'open'}, { label: 'Pending', value: 'pending'}, { label: 'Closed', value: 'closed'} ] }
            ]" />
        <!-- Lookedup Cases -->
        <Table :title="tableTitle" icon="briefcase" class="flex-grow ml-2">
            <template #header><div v-if="lastLookup.complete" class="flex flex-row items-center rounded px-2" title="View by Resolved Status"><Icon id="archive-box" class="mr-2 h-6 w-6" />
                <Field type="select" @change="viewResolvedChanged" title="Resolved Status" initialValue="all" :options="[{label: 'View All', value: 'all'},{label: 'View Resolved', value: 'resolved'},{label: 'View Not Resolved', value: 'unresolved'}]"  :selectOffsetCancel="true"
                class="px-1 rounded-sm fnui-content py-0 ml-1 mb-1" :noPadding="true" /></div></template>
            <Pages v-if="pages > 1" :page="page" :pages="pages" :total="total" @next="nextPage"  @previous="previousPage" />
                
                <div v-if="!lastLookup.complete">
                    <Notice notice="Use the form on the left to lookup cases" />
                </div>
                <div v-else-if="cases.length == 0">
                    <Notice notice="No cases found" />
                </div>
                <div v-for="c in cases" :key="c.id" :class="`${ (c['flags']['RESOLVED'] && viewResolved == 'resolved') || (!c['flags']['RESOLVED'] && viewResolved == 'unresolved') || viewResolved == 'all' ? '' : 'hidden' } py-4 border-b flex flex-row flex-wrap cursor-pointer fnui-content-hover`"
                    @click="focusCase(c)">
                    

                <div v-for="field in 
                [ { title: 'Created At', icon: 'clock', value: displayDate(c['created_at']) },
                  { title: 'Last Updated', icon: 'clock', value: displayDate(c['updated_at']) },
                  { title: 'RTN', icon: 'building-office', label: 'RTN', value: c['participant_id'] } ,
                  { title: 'Status', icon: 'user', label: 'Status', value: c['status'] },
                  { title: 'Resolved', icon: 'user', label: 'Resolved', value: c['flags']['RESOLVED'] } ]" 
            :key="field.title" :title="field.title" class="flex items-center w-1/3 leading-4 px-2">
                <Icon v-if="field.icon" :id="field.icon" class="h-4 w-4 mr-1" />{{field.label ? field.label + ': ' : ''}}{{field.value}}
                </div>

                </div>
            <Pages v-if="pages > 1" :page="page" :pages="pages" :total="total" @next="nextPage"  @previous="previousPage" />
        </Table>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'

import { ref } from 'vue'

import Button from '@/components/fnui/Button.vue'
import Display from '@/components/fnui/Display.vue'
import Field from '@/components/fnui/Field.vue'
import Form from '@/components/fnui/Form.vue'
import Header from '@/components/fnui/Header.vue'
import Icon from '@/components/fnui/Icon.vue'
import Notice from '@/components/fnui/Notice.vue'
import Pages from '@/components/fnui/Pages.vue'
import Table from '@/components/fnui/Table.vue'

export default {
    name: 'Cases',
    components: {
        Button,
        Display,
        Field,
        Form,
        Header,
        Icon,
        Notice,
        Pages,
        Table
    },
	computed: {
		...mapStores(useUserStore, useAppStore),
        initialRtn(){
            if(this.userStore.participantIds && this.userStore.participantIds[0]){
                return this.userStore.participantIds[0]
            }
            return '';
        },
        isReturnRequest(){
            if(!this.focusedMessage || !this.focusedMessage.detail) return false;
            return (this.focusedMessage.description.toLowerCase().indexOf('return request') != -1);
        },
        isDuplicateRequest(){
            if(!this.focusedMessage || !this.focusedMessage.detail) return false;
            return (this.focusedMessage.detail.possible_duplicate)
        },
        isMissingInfoRequest(){
            if(!this.focusedMessage || !this.focusedMessage.detail) return false;
            return (this.focusedMessage.detail.missing_info || this.focusedMessage.detail.incorrect_info)
        },
        responseFields(){
                let fields = [];                
                this.updateResponseFields;
                //Return request fields
                if(this.isReturnRequest){                    
                    let form = this.$refs.respondForm

                    fields = [{type: 'select', label: 'Status', id: 'status', changeEmit: 'statusUpdated',
                        options: [ 
                        { label: 'IPAY - (Return Request Accepted)', value: 'IPAY'}, 
                        { label: 'RJCR - (Return Request Rejected)', value: 'RJCR'}, 
                        { label: 'PDCR - (Return Request Pending) ', value: 'PDCR'}, 
                        { label: 'PECR - (Partially Executed Return Request)', value: 'PECR'}]}];

                    if(form && form.getValue('status') == 'RJCR'){
                        fields.push({ type: 'reasonCode', label: 'Reason Code', id: 'code' })
                        fields.push({ type: 'area', label: 'Additional Info(optional)', id: 'reason' })
                    }
                }
                else if(this.isMissingInfoRequest){
                    fields = [{ type: 'infoCode', label: 'Status', id: 'status' }]
                }
                else if(this.isDuplicateRequest){
                    fields = [{ type: 'text', label: 'Duplicate Transfer ID', id: 'duplicateTransferId' }]
                }
                return fields;
        },
        tableTitle(){
            if(!this.lastLookup.participant || !this.lastLookup.status) return 'Cases';

            return `${this.lastLookup.status} Cases - RTN ${this.lastLookup.participant}`;
        }
  },
	methods: {    
        canRespond(message){
            if(message.flags && message.flags.DIR_SEND == true)
                return false;

            return (message.case_id && message.case_id.length > 0)
        },
        detailHeader(detail, includeCode){
            if(detail.detail.possible_duplicate)
                return 'Possible Duplicate';
            else if(detail.detail.missing_info)
                return 'Missing Information';
            else if(detail.detail.incorrect_info)
                return 'Incorrect information';

            let reason = this.appStore.codes.return_reasons[detail.detail.reason_code]
            if(reason)
                return `${includeCode ? '('+detail.detail.reason_code+')' : ''} ${reason.name}${includeCode ? ':' : ''}`;
            return  ''
        },
        detailDescription(detail){            
            if(!detail.detail || !detail.detail.reason_code) return '';
            let reason = this.appStore.codes.return_reasons[detail.detail.reason_code]
            if(!reason) return '';
            return `${reason.desc} `;//${detail.detail.reason_text} ${detail.detail.status ? ' - Status: ' + detail.detail.status : ''}`
        },
        displayDate(raw){
            let utcDate = new Date(raw)
            let options = {
                timeZoneName: 'short',
                year: 'numeric',
                month: 'numeric', 
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }
            let localDate = new Intl.DateTimeFormat(navigator.language, options).format(utcDate);
            return localDate;
        },
        focusCase(c){
            this.mode = '';
            this.focusedCase = c;
            this.fetchCaseDetails(c.id)
        },
        focusMessage(m){
            this.focusedMessage = m;
        },
        formatDate(raw){
            let date = new Date(raw).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            });
            return date;
        },
        async fetchCaseDetails(caseId){
            try{
                let resp = await this.$fnapi.get(`/cct/cases/messages/${caseId}`) 
                this.focusedCase.messages = resp.data
            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
        async fetchTransferDetails(id){
            try{
                let resp = await this.$fnapi.get(`/cct/messages/${id}`) 
                this.focusedCase.messages = resp.data
            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
        async loadCases(vals){
            let url = `https://fednow.stage.mnolb.com/api/cct/cases/${vals.status}?participant_id=${vals.participant}&page=${this.page}`
            let resp = await this.$fnapi.get(url) 

            this.lastLookup.complete = true
            this.cases = resp.data.items;
            this.page = resp.data.page
            this.pages = resp.data.pages
            this.total = resp.data.total
            console.log('resp: ', resp)
        },
        lookup(vals){            
            this.page = 1;
            this.lastLookup = vals
            localStorage['lastCaseLookup'] = JSON.stringify(this.lastLookup)
            this.loadCases(vals);
        },
        nextPage(){
            if(this.page >= this.pages) return;

            this.page++;
            this.loadCases(this.lastLookup);
        },
        previousPage(){
            if(this.page <= 1) return;

            this.page--;
            this.loadCases(this.lastLookup);
        },
        respond(message){
            this.respondingTo = message
            this.focusedMessage = message
            this.mode = 'respond';
        },
        respondSubmit(vals){       
            
                //Check the focused message for fields to display
                if(this.isReturnRequest)
                        this.respondReturnRequest(vals);
                else if(this.isMissingInfoRequest)
                    this.respondMissingInfo(vals);
                else if(this.isDuplicateRequest)
                    this.respondInfoDuplicate(vals);
                else
                    console.log('respondSubmit: unspecified form');
        },
        respondInfoDuplicate(vals){
            
            let data = {
                case_id: this.focusedCase.id,
                duplicate_transfer_id: vals.duplicateTransferId
            };
            this.$fnapi.post('/cct/information-request-response-duplicate', data).then(r=>{
                    this.fetchCaseDetails(this.focusedCase.id) // refocus case to load new response
                    this.mode = 'responseSent'
                    console.log('responeInfoDuplicate.r: ', r);
            })
            .catch((ex) => {  
                console.log('---respondInfoDuplicate ERROR---')    
                console.log('Data: ', data)          
                console.log(ex.response.data)          
                this.$refs.respondForm.localError = ex.response.data.detail[0].msg

            }); 
        },
        respondMissingInfo(vals){            
            let data = {
                case_id: this.focusedCase.id,
                status: vals.status
            };
            this.$fnapi.post('/cct/information-request-response', data).then(r=>{
                    this.fetchCaseDetails(this.focusedCase.id) // refocus case to load new response
                    this.mode = 'responseSent'
            })
            .catch((ex) => {  
                console.log('---respondMissingInfo ERROR---')    
                console.log('Data: ', data)          
                console.log(ex.response.data)          
                this.$refs.respondForm.localError = ex.response.data.detail[0].msg
            }); 
        },
        respondReturnRequest(vals){            
            let data = {
                case_id: this.focusedCase.id,
                status: vals.status,
                reason_code: vals.code,
                reason_text: vals.reason,
            };
            this.$fnapi.post('/cct/return-request-response', data).then(r=>{
                    this.fetchCaseDetails(this.focusedCase.id) // refocus case to load new response
                    this.mode = 'responseSent'
            })
            .catch((ex) => {  
                console.log('---respondReturnRequest ERROR---')    
                console.log('Data: ', data)          
                console.log(ex.response.data)          
                this.$refs.respondForm.localError = ex.response.data.detail[0].msg
            }); 
        },
        responseStatusUpdated(val){
            //Update value to trigger responseFields to recompute
            this.updateResponseFields++;
        },
        viewResolvedChanged(evt){
            this.viewResolved = evt.target.value

        }
	},
	mounted(){
        document.title = 'Cases-FedNow';

        //Check for executing last search
        if(localStorage['reloadCases'] == 1){
            localStorage['reloadCases'] = 0;
            if(localStorage['lastCaseLookup']){
                this.loadCases(JSON.parse(localStorage['lastCaseLookup']))
            }
        }
	},
    setup(){
        const cases = ref([])
        const focusedCase = ref({})
        const focusedMessage = ref({})
        const mode = ref('')
        const page = ref(1)
        const pages = ref(1)
        const total = ref(0)
        const lastLookup = ref({})
        const respondingTo = ref({})
        const viewResolved = ref('all')
        const updateResponseFields = ref(0)

        return {  
            cases, 
            focusedCase,
            focusedMessage,
            lastLookup,
            mode,
            page,
            pages,
            respondingTo,
            total,
            updateResponseFields,
            viewResolved
         };
    }
}
</script>
