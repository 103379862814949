<template>
    <div :class="`${mainClass}  ${isVert ? 'flex-col' : 'flex-row'}  
        ${url ? 'cursor-pointer' : 'cursor-default'} 
        ${isSiteWide ? 'border-b-2' : 'border-2 rounded-sm' }
        flex flex-row items-center justify-center shadow-lg `">
        <div :class="`${opacity} max-w-screen-lg flex  
        ${isVert ? 'flex-col' : 'flex-row'} items-center justify-start w-full p-2`">
            <div v-if="title" :class="`${mainClass}-alt ${isVert ? 'w-full mb-2' : ''} p-1 uppercase text-xl whitespace-nowrap rounded-sm m-1 flex flex-row items-center`">
                <Icon v-if="icon != 'none'" :id="usedIcon" :class="`h-6 w-6 mr-2 flex-shrink-0`"  @click="clickNotice()"/>
                <span @click="clickNotice()" class="font-bold tracking-tighter mr-1 flex-grow">{{title}}</span>
                <Icon v-if="isVert && showClose" id="x" class="h-8 w-8 cursor-pointer flex-shrink-0 opacity-60 hover:opacity-100"  
                @click="this.$emit('close')" key=""/>
            </div>
            <Icon v-else-if="icon != 'none'" :id="usedIcon" class="h-6 w-6 mr-2 flex-shrink-0" />
            <div :class="`${opacity} leading-4 flex-grow `">{{notice}}</div>
            <slot />
            <Icon v-if="!isVert && showClose" id="x" class="h-8 w-8 cursor-pointer flex-shrink-0 opacity-60 hover:opacity-100"  @click="this.$emit('close')" key=""/>
        </div>    
    </div>
</template>

<script>
import { ref } from 'vue'

import Button from "@/components/fnui/Button.vue";
import Icon from "@/components/fnui/Icon.vue";

export default {
    name: "Notice",   
    components: {
        Button,
        Icon
    },
    props:{
        icon: String,
        isReversed: Boolean,
        isSiteWide: Boolean,
        isVert: Boolean,
        theme: String,
        title: String,
        url: String,
        notice: String,
        showClose: Boolean
    },
    computed: {
        usedIcon(){
            if(this.$props.icon) return this.$props.icon;
            
            switch(this.$props.theme){
                case 'news':
                    return 'newspaper';

                case 'alert':
                    return 'exclamation';

                case 'notice':
                    return 'info';

                default:
                case 'highlight':
                    return 'info';
            }            
        },
        mainClass(){
            switch(this.$props.theme){
                case 'news':
                    return 'fnui-approved';
                    
                default:
                case 'content':
                    return 'fnui-content';

                case 'alert':
                    return 'fnui-alert';

                case 'notice':
                    return 'fnui-notice';

                case 'highlight':
                    return 'fnui-highlight';
            }
        },
        opacity(){
            if(this.$props.url)
                return 'opacity-90 hover:opacity-100 duration-100'

            return '';
        }
    },
    methods:{
        clickNotice(){
            if(this.$props.url)
                window.open(this.$props.url);
        }
    },
    setup(){      
        
        return{
        }
    }
}

</script>
